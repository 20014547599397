import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import store from 'src/app/store';
import { setIsTravelwareAvailable } from 'src/app/store/appSlice';

export const commonAxios = axios.create();

commonAxios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        if (error.response?.status && error.response?.status >= 500) {
            Sentry.captureException(error, (scope) => {
                scope.setTag('Code', error.response?.status);
                scope.setTag('window.location.href', window.location.href);

                // More sophisticated information per error
                scope.setContext('Request/Response context', {
                    requestUrl: error?.config?.url,
                    requestBody: JSON.stringify(error?.config?.data),
                    responseBody: JSON.stringify(error?.response?.data),
                    code: error.response?.status,
                    method: error.config?.method,
                });
                return scope;
            });
            store.dispatch(setIsTravelwareAvailable(false));
        }
        // Enable this in case you want to log all errors to Sentry
        return Promise.reject(error);
    }
);

/** The commented code belows still might be needed in the future
 *  The code is used to capture the error and send it to Sentry for monitoring and debugging.
 *  It captures important information such as the endpoint, request body, response body, and the status code
 * */

// function captureSentryException(error: AxiosError) {
//     const sentryDsn = getEnvironment('REACT_APP_SENTRY_DSN');
//     if (sentryDsn !== '$REACT_APP_SENTRY_DSN') {
//         Sentry.captureException(error, (scope) => {
//             // For easier filtering
//             scope.setTag('Code', error.response?.status);
//             scope.setTag('window.location.href', window.location.href);
//             scope.setTag('App locale', getAppLocale());
//             scope.setTag('URL', constructUrl(error.config?.url));

//             // More sophisticated information per error
//             scope.setContext('Request/Response context', {
//                 requestUrl: error?.config?.url,
//                 requestBody: JSON.stringify(error?.config?.data),
//                 responseBody: JSON.stringify(error?.response?.data),
//                 code: error.response?.status,
//                 method: error.config?.method,
//             });
//             return scope;
//         });
//     } else {
//         console.error('Sentry DSN not set');
//     }
// }

// /** Takes the complete url, e.g. https://staging.app.travelflow.com/api/v2/events/a9386afd-c098-4fa3-85b4-3f6c4a344721/accommodations
//  * and turns it into /events/{id}/accommodations.
//  * This is used to easily filter and group errors in Sentry.
//  */
// function constructUrl(url: string | undefined) {
//     if (!url) return '';

//     let finalUrl = '';

//     /** https://staging.app.travelflow.com/api/v2/events/a9386afd-c098-4fa3-85b4-3f6c4a344721/accommodations
//      * Into
//      * [events, a9386afd-c098-4fa3-85b4-3f6c4a344721, accommodations]
//      * First 5 elements are ignored, as they are 'https:', '', 'staging.app.travelflow.com', 'api', 'v2'
//      */
//     const urlParts = url?.split('?')[0].split('/').slice(5);

//     urlParts?.forEach((value) => {
//         // 36 = UUID length
//         if (value?.length === 36) {
//             // This is intentional.
//             // Instead of logging the actual ID, put a placeholder here for easier grouping on Sentry.
//             finalUrl += `/{id}`;
//         } else {
//             // If the value is not a UUID, we add it to the URL
//             if (value) {
//                 finalUrl += `/${value}`;
//             }
//         }
//     });

//     return finalUrl;
// }
