import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Body } from 'src/view/components/Body/Body';
import { Heading } from 'src/view/components/Heading/Heading';
import Button from '../Button/Button';
import $ from './TakeOverMessage.module.scss';

interface Props {
    callToActionLabel: string;
    description: string;
    eventName?: string | null;
    imageAlt: string;
    imageSrc: string;
    onCallToActionClick: () => void;
    title: string;
    copyRight?: string;
}

const TakeOverMessage: React.FunctionComponent<Props> = ({
    callToActionLabel,
    description,
    eventName = null,
    imageAlt,
    imageSrc,
    onCallToActionClick,
    title,
    copyRight,
}) => {
    const { t } = useTranslation();

    return (
        <section className={$.wrap}>
            <div className={$.imgWrap}>
                {copyRight && <div className={$.copyright}>{copyRight}</div>}
                <img
                    className={$.img}
                    srcSet="/images/session-end/timeout-page-foto-400w.webp 400w, /images/session-end/timeout-page-foto-600w.webp 600w, /images/session-end/timeout-page-foto-800w.webp 800w, /images/session-end/timeout-page-foto-1000w.webp 1000w, /images/session-end/timeout-page-foto-1200w.webp 1200w, /images/session-end/timeout-page-foto-1600w.webp 1600w, /images/session-end/timeout-page-foto-2000w.webp 2000w"
                    sizes="(max-width: 400px) 400px, (max-width: 600px) 600px, (max-width: 800px) 800px, (max-width: 1000px) 1000px, (max-width: 1200px) 1200px, (max-width: 1600px) 1600px, (min-width: 1601px) 2000px"
                    src="/images/session-end/timeout-page-foto.jpg"
                    loading="lazy"
                    alt={t('sessionend_sessionhasended', 'Unfortunately, your session has ended')}
                />
            </div>
            <Heading align="center" variant="h1" className={$.title}>
                {title}
            </Heading>
            <div className={$.content}>
                <Body align="center">{description}</Body>
                {eventName && (
                    <Heading variant="h4" align="center" uppercase={false}>
                        {eventName}
                    </Heading>
                )}

                <Button
                    className={$.button}
                    text={callToActionLabel}
                    onClick={onCallToActionClick}
                />
            </div>
        </section>
    );
};

export default TakeOverMessage;
