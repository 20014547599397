import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { appConstants } from 'src/app/constants/app';
import { useTypedDispatch } from 'src/app/store';
import { resetSession, selectReferrerUrl, selectSession } from 'src/app/store/appSlice';
import { selectEventDetail } from 'src/app/store/eventSlice';
import { resetOrder, selectOrder } from 'src/app/store/orderSlice';
import { EventRouteParams, RouteHelper } from 'src/app/utils/RouteHelper';
import { getLastKnownEntryPoint } from 'src/app/utils/lastKnownEntryPointHelper';
import {
    getSessionEndBackLink,
    removeBookingCode,
    setSessionEndBackLink,
} from 'src/data/services/cache';
import { Page, TakeOverMessage } from 'src/view/components';
import $ from './SessionEnd.module.scss';

export const SessionEnd: React.FunctionComponent<RouteComponentProps> = () => {
    const { t } = useTranslation();
    const eventDetail = useSelector(selectEventDetail);
    const dispatch = useTypedDispatch();

    const link = useFormulatedBackLink();

    useEffect(() => {
        if (!link) return;

        setSessionEndBackLink(link);

        // Perform the side effects
        dispatch(resetOrder());
        removeBookingCode();
        dispatch(resetSession);
    }, [link]);

    const goBack = () => {
        // Do not use react router or navigate here because these are full urls.
        window.location.href = link;
    };

    return (
        <Page step={1} mustHideTravelDetails>
            <div className={$.wrap}>
                <TakeOverMessage
                    callToActionLabel={t('sessionend_bookthisevent')}
                    description={t('sessionend_hasendeddescription')}
                    eventName={eventDetail && eventDetail.name}
                    onCallToActionClick={goBack}
                    title={t('sessionend_bookingalmostcomplete')}
                />
            </div>
        </Page>
    );
};

/** Returns the most suitable link which user will use to navigate. */
const useFormulatedBackLink = () => {
    const session = useSelector(selectSession);
    const eventDetail = useSelector(selectEventDetail);
    const { eventId } = useParams<{ eventId: string }>();
    const partnerReferrerUrl = useSelector(selectReferrerUrl);
    const order = useSelector(selectOrder);

    const formedUrlParams = useMemo(() => {
        if (!session) return null;

        /** Prefer order package type over session preference (which takes into consideration only the initial URL, and not the actual order package type) */
        const packageType = order ? order.packageType : session.preferredPackageType;

        /** We can get those only from the session once, before it is reset. */
        const params: EventRouteParams = {
            package_type: packageType || undefined,
            category_id: session.baseTicketCategoryId || undefined,
        };

        return params;
    }, [session]);

    /** The desired link we are looking to formulate, under ideal circumstances */
    const link = useMemo(() => {
        if (session && formedUrlParams) {
            return RouteHelper.getTicketRoute(session.eventId, formedUrlParams);
        }

        return null;
    }, [session, formedUrlParams, eventId]);

    const fallbackLink = useMemo(() => {
        const cachedLink = getSessionEndBackLink() || '';
        /** Verify that the cached link includes the correct event id found in the URL */
        if (cachedLink.includes(eventDetail?.id || eventId)) {
            return cachedLink;
        }

        /** Check whether the URL includes the eventId. It could be that eventId is null */
        if (eventId && window.location.pathname.includes(eventId)) {
            return RouteHelper.getTicketRoute(eventId, {});
        }

        /** If all else fails, return the last known entry point or the partner referrer URL, or link to our main website */
        return (
            getLastKnownEntryPoint() ||
            partnerReferrerUrl?.toString() ||
            appConstants.fallbackWebsite
        );
    }, [eventDetail, eventId, partnerReferrerUrl]);

    return link || fallbackLink;
};
