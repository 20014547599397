import { AppLocale, localizeOrigin } from 'src/app/constants/appLocale';
import { OrderTypeUtil } from 'src/app/utils/utils';
import { AvailabilityEntity } from 'src/data/models/Availability';
import { BookResult, BookResultEntity } from 'src/data/models/BookResult';
import { COUPON_TYPE, Discount } from 'src/data/models/Discount';
import { ErrorListResponse } from 'src/data/models/ErrorListResponse';
import { Order, OrderEntity, PackageType } from 'src/data/models/Order';
import { OrderSummaryEntity } from '../models/OrderSummary';
import { commonAxios } from './axios';
import { Body, fetchUrl, getTravelwareApiConfig } from './fetchUrl';

export type OrderMetadataKey =
    | 'UTM_SOURCE'
    | 'UTM_CAMPAIGN'
    | 'UTM_MEDIUM'
    | 'REF_UTM_SOURCE'
    | 'REF_UTM_CAMPAIGN'
    | 'REF_UTM_MEDIUM'
    | 'SOURCE'
    | 'TCO_DOMAIN'
    | 'TCO_LANGUAGE'
    | 'TCO_COUNTRY'
    | 'TCO_REFERRER'
    | 'GTM_CLIENT_ID'
    | 'COOKIE_CONSENT_MARKETING'
    | 'COOKIE_CONSENT_STATISTICS'
    | 'AFFILIATE_ID'
    | 'AFFILIATE_MISC'
    | 'GTM_SESSION_ID'
    | 'GCL_ID'
    | 'FB_CLICK_ID'
    | 'FB_CLIENT_ID'
    | 'MS_CLICK_ID';

export type OrderMetadata = {
    key: OrderMetadataKey;
    value: string;
};

export type RoomOccupancy = {
    adults: number;
    children?: string[];
};

export type OrderBaseDetails = {
    occupancy: RoomOccupancy[];
    dateStart: string | null;
    dateEnd: string | null;
    packageType: PackageType;
    baseTicketCategoryId?: string | null;
    source?: string;
};

export interface OrderAccommodationUpdateBody {
    id: string;
    partner?: string;
    partner_id?: string;
    rates: {
        id: string;
        room_id: string;
        room_name: string;
    }[];
}

function getOrderDetailsBody(
    occupancy: RoomOccupancy[],
    dateStart: string,
    dateEnd: string,
    packageType: PackageType,
    baseCategoryId: string | null,
    source = 'CHECKOUT'
): Body {
    let body: Body = {
        package_type: packageType,
        occupancy,
        base_ticket_cat_id: baseCategoryId,
        source,
    };

    if (OrderTypeUtil.hasHotel(packageType)) {
        body = {
            ...body,
            date_start: dateStart,
            date_end: dateEnd,
        };
    }

    return body;
}

export async function startOrder(
    eventId: string,
    occupancy: RoomOccupancy[],
    dateStart: string,
    dateEnd: string,
    packageType: PackageType,
    baseCategoryId: string | null,
    source?: string
) {
    const body = getOrderDetailsBody(
        occupancy,
        dateStart,
        dateEnd,
        packageType,
        baseCategoryId,
        source
    );

    body.event_id = eventId;

    return fetchUrl<OrderEntity>({
        endpoint: '/v2/orders',
        method: 'POST',
        body,
        getSecret: true,
    });
}

export async function updateOrderPreferences(
    orderId: string,
    orderSecret: string,
    occupancy: RoomOccupancy[],
    dateStart: string,
    dateEnd: string,
    packageType: PackageType,
    baseCategoryId: string | null
) {
    const body = getOrderDetailsBody(occupancy, dateStart, dateEnd, packageType, baseCategoryId);

    return fetchUrl<OrderEntity>({
        endpoint: `/v2/orders/${orderId}/preferences`,
        method: 'PUT',
        body,
        secret: orderSecret,
    });
}

export async function getOrder(orderId: string, orderSecret: string, locale: AppLocale) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v2/orders/${orderId}`,
        method: 'GET',
        secret: orderSecret,
    }).then((orderEntity) => new Order(orderEntity));
}

export async function getOrderSummary(orderId: string, orderSecret: string) {
    return fetchUrl<OrderSummaryEntity>({
        endpoint: `/v2/orders/${orderId}/summary`,
        method: 'GET',
        secret: orderSecret,
    });
}

export async function setOrderTickets(orderId: string, orderSecret: string, ticketId: string) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v2/orders/${orderId}`,
        method: 'PUT',
        body: {
            tickets: [
                {
                    id: ticketId,
                },
            ],
        },
        secret: orderSecret,
    });
}

export async function setOrderCoupon(orderId: string, orderSecret: string, coupon: string) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v2/orders/${orderId}`,
        method: 'PUT',
        body: {
            coupons: [
                {
                    code: coupon,
                },
            ],
        },
        secret: orderSecret,
    }).then((orderEntity) => new Order(orderEntity));
}

export async function setOrderAccommodation(
    orderId: string,
    orderSecret: string,
    accommodation: OrderAccommodationUpdateBody
) {
    const accommodationBody: Body = {
        id: accommodation.id,
        partner: accommodation.partner,
        partner_id: accommodation.partner_id,
        rates: accommodation.rates,
    };

    return fetchUrl<OrderEntity>({
        endpoint: `/v2/orders/${orderId}`,
        method: 'PUT',
        body: {
            accommodation: [accommodationBody],
        },
        secret: orderSecret,
    });
}

export interface TravellerDTO {
    birth_date: string;
    first_name: string;
    last_name: string;
    nationality: string;
}

export interface BookerDTO extends TravellerDTO {
    country_code: string;
    email: string;
    phone_number: string;
    address_line_1?: string;
    address_line_2?: string;
    zip_code?: string;
    city?: string;
    company_name?: string;
}

export async function setOrderTravelers(
    orderId: string,
    orderSecret: string,
    customer: BookerDTO,
    travellers: TravellerDTO[],
    customerReference?: string,
    restrictionsConfirmed?: boolean
) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v2/orders/${orderId}`,
        method: 'PUT',
        body: {
            customer,
            travellers,
            customer_reference: customerReference,
            customer_confirmed_no_away_fan: restrictionsConfirmed,
        },
        secret: orderSecret,
    }).then((orderEntity) => new Order(orderEntity));
}

export function getAvailability(orderId: string, orderSecret: string, forceFresh = 0) {
    return fetchUrl<AvailabilityEntity>({
        endpoint: `/v2/orders/${orderId}/availability`,
        method: 'POST',
        body: {
            forceFresh,
        },
        secret: orderSecret,
    });
}

export function validateOrder(orderId: string, orderSecret: string) {
    return fetchUrl<ErrorListResponse>({
        endpoint: `/v2/orders/${orderId}/validate`,
        method: 'POST',
        body: {
            forceFresh: 0,
        },
        secret: orderSecret,
    });
}

/**
 * Validate the payment request.
 *
 * Example error:
 * {
 *      "code": "ERROR_TICKET_NOT_AVAILABLE",
 *      "payload": {
 *          "id": "dc4f9d1a-bc72-4a50-8650-704e4d105956",
 *          "num_available": 0,
 *          "num_requested": 1
 *      }
 * }
 */
export function validatePaymentRequest(paymentRequestId: string) {
    return fetchUrl<ErrorListResponse>({
        endpoint: `/v2/payment-requests/${paymentRequestId}/validate`,
        method: 'GET',
    });
}

/** Book the order for payment. */
export async function book(
    orderId: string,
    orderSecret: string,
    options: { subscribeNewsletter?: boolean } = {}
) {
    return fetchUrl<BookResultEntity>({
        endpoint: `/v2/orders/${orderId}/book`,
        method: 'POST',
        body: {
            subscribe_customer_email: options.subscribeNewsletter === true,
        },
        secret: orderSecret,
    }).then((bookResult) => new BookResult(bookResult));
}

export async function addBookingFee(orderId: string, orderSecret: string, pax: number, id: string) {
    return fetchUrl<BookResultEntity>({
        endpoint: `/v2/orders/${orderId}`,
        method: 'PUT',
        body: {
            extras: [
                {
                    id,
                    quantity: pax,
                },
            ],
        },
        secret: orderSecret,
    });
}

interface AdyenPaymentSession {
    session_id: string;
    session_data: string;
}

export async function createAdyenPaymentSession(orderId: string, orderSecret: string) {
    const travelwareApiConfig = getTravelwareApiConfig();

    if (!travelwareApiConfig) {
        throw new Error('Missing travelwareApiConfig');
    }

    const origin = localizeOrigin(window.location.origin);
    const callbackUrl = encodeURIComponent(
        `${origin}/completed?orderId=${orderId}&orderSecret=${orderSecret}`
    );

    const createSessionUrl = new URL(
        `${travelwareApiConfig.baseUrl}/payments/pay/${orderId}?gateway=SESSION&callback=${callbackUrl}`
    );

    return commonAxios
        .get<AdyenPaymentSession>(createSessionUrl.toString())
        .then((response) => response.data);
}

export async function putMetadata(orderId: string, orderSecret: string, metadata: OrderMetadata[]) {
    return fetchUrl({
        endpoint: `/v2/orders/${orderId}/metadata`,
        method: 'PUT',
        body: {
            metadata: metadata.map((meta) => ({
                key: meta.key.toString(),
                value: meta.value,
            })),
        },
        secret: orderSecret,
    });
}

export interface DiscountResponse {
    type: COUPON_TYPE;
    value: string;
}

export async function addDiscountOrVoucher(orderId: string, secret: string, code: string) {
    return fetchUrl<Discount>({
        endpoint: `/v2/orders/${orderId}/discount-or-voucher`,
        method: 'POST',
        secret,
        body: {
            code,
        },
    });
}

export async function deleteDiscountOrVoucher(orderId: string, secret: string, code: string) {
    return fetchUrl<Discount>({
        endpoint: `/v2/orders/${orderId}/discount-or-voucher/${code}`,
        method: 'DELETE',
        secret,
    });
}
