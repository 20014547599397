import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isOlympicsSeries, olympicPreferredPaymentMethod } from 'src/app/constants/olympics';
import { getProgressSteps } from 'src/app/constants/progressSteps';
import { RootState, TypedDispatch } from 'src/app/store';
import {
    initializeSession,
    selectInitializedSession,
    selectPartnerConfig,
    selectPartnerConfigCheckout,
    selectReferrerUrl,
    selectSession,
    Session,
} from 'src/app/store/appSlice';
import {
    OrderSummary,
    removeDiscountFromOrder,
    RemoveDiscountStatusType,
    selectAvailability,
    selectDiscountInputOpen,
    selectDiscountRemoveStatus,
    selectDiscounts,
    selectDiscountStatus,
    selectOrder,
    selectOrderSummary,
    selectSelectedAccommodation,
    selectSelectedAccommodationIncludesBreakfast,
    selectSelectedTicket,
    selectVouchers,
} from 'src/app/store/orderSlice';
import { hasMarketingCookieConsent } from 'src/app/utils/consent';
import { Accommodation } from 'src/data/models/Accommodation';
import { Availability } from 'src/data/models/Availability';
import { Config } from 'src/data/models/Config';
import { ConfigCheckout } from 'src/data/models/ConfigCheckout';
import { Discount } from 'src/data/models/Discount';
import { EventDetail } from 'src/data/models/EventDetail';
import { Order, PackageType } from 'src/data/models/Order';
import { Ticket } from 'src/data/models/Ticket';
import * as Cache from 'src/data/services/cache';
import { getGateways } from 'src/data/services/payments';
import {
    Footer,
    Header,
    ProgressCard,
    ProgressCardSmall,
    TravelDetail,
    WhyPartner,
} from 'src/view/components';
import Card, { CardBody } from 'src/view/components/Card';
import CountDownTimer from 'src/view/components/CountDownTimer/CountDownTimer';
import LiveChat from 'src/view/components/LiveChat/LiveChat';
import { VSpacer } from 'src/view/components/Page';
import { SizeEnum } from 'src/view/components/Page/VSpacer';
import { ProgressBar } from 'src/view/components/ProgessBar/ProgressBar';
import TrustpilotWidget from 'src/view/components/TrustpilotWidget';
import FaqBlockFeature, { getFaqData } from 'src/view/features/Faq/FaqBlockFeature';
import $ from './Page.module.scss';

interface ReduxProps {
    dispatch: TypedDispatch<RootState>;
    availability?: Availability | null;
    config?: Config | null;
    configCheckout?: ConfigCheckout | null;
    referrerUrl?: URL | null;
    order?: Order | null;
    orderSelectedTicket?: Ticket | null;
    orderSelectedAccommodation?: Accommodation | null;
    orderSelectedAccommodationIncludesBreakfast?: boolean | null;
    showDiscountInput: boolean;
    discountRemoveStatus: RemoveDiscountStatusType;
    initializedSession: boolean;
    discounts: Discount[];
    vouchers: Discount[];
    orderSummary: OrderSummary | null;
    session: Session | null;
}

interface Props extends WithTranslation, ReduxProps {
    children: React.ReactNode;
    eventDetail?: EventDetail;
    mustHideTravelDetails?: boolean;
    showPricing?: boolean;
    step: number;
    hideCountDown?: boolean;
}

interface State {
    orderDetailOpen: boolean;
    noAccommodationOverlay: boolean;
    discountInputValue: string;
}

interface RouteParams {
    eventId: string;
}

class PageComponent extends React.Component<Props & RouteComponentProps<RouteParams>, State> {
    public constructor(props: Props & RouteComponentProps<RouteParams>) {
        super(props);

        this.state = {
            orderDetailOpen: false,
            noAccommodationOverlay: false,
            discountInputValue: '',
        };
        this.toggleDetails = this.toggleDetails.bind(this);
        this.toggleHotelDetail = this.toggleHotelDetail.bind(this);
    }

    public componentDidMount() {
        document.querySelector('body')?.scrollTo(0, 0);

        const { initializedSession, dispatch, session } = this.props;
        const { eventId } = this.props.match.params;

        if (eventId && !initializedSession) {
            dispatch(
                initializeSession(eventId, {
                    baseTicketCategoryId: session?.baseTicketCategoryId || undefined,
                    preferredPackageType:
                        (session?.preferredPackageType as PackageType) || PackageType.TICKET_ONLY,
                })
            );
        }
    }

    public toggleHotelDetail = (accommodation?: Accommodation) => {
        this.setState({
            orderDetailOpen: false,
        });
    };

    public toggleDetails() {
        const { orderDetailOpen } = this.state;
        this.setState({ orderDetailOpen: !orderDetailOpen });
    }

    public render() {
        const {
            t,
            children,
            step,
            eventDetail,
            mustHideTravelDetails,
            showPricing = true,
            config,
            configCheckout,
            referrerUrl,
            availability,
            orderSelectedTicket,
            orderSelectedAccommodation,
            orderSelectedAccommodationIncludesBreakfast,
            order,
            discounts,
            vouchers,
            discountRemoveStatus,
            orderSummary,
            hideCountDown,
            dispatch,
        } = this.props;
        const { orderDetailOpen, noAccommodationOverlay } = this.state;

        const pageData = {
            stepIndicator: {
                logo: {
                    url: configCheckout?.general.logo || '',
                    title: '',
                },
                phoneNumber: t('_hardcodedSupportPhoneNumber'),
                whatsappUrl: t('_hardcoded_support_whatsapp_url'),
                active: step,
                partnerWebsiteUrl: isOlympicsSeries(eventDetail?.serie.id || '')
                    ? t('_hardcoded_olympics_landing_page_url')
                    : referrerUrl,
            },
            ...getFaqData(t),
        };

        const onRemoveDiscount = async (d: Discount[]) => {
            if (!order) return;
            await dispatch(removeDiscountFromOrder(order.id, order.secret, d));
        };

        const { priceTotal = '0', numPax = 0 } = orderSummary || {};

        const totalOrderPrice = Number(priceTotal) * numPax;

        const mustShowCountdown = availability && eventDetail && !hideCountDown;
        const hasBookingCode = Cache.getBooking();

        return (
            <>
                <div
                    className={`${$.main} ${availability && eventDetail && $.orderProgressIsOpen}`}
                >
                    <Header
                        {...pageData.stepIndicator}
                        uspData={pageData.whyEquipo}
                        eventId={eventDetail && eventDetail.id}
                        eventDetail={eventDetail}
                        showTrustpilotAsUspOnMobile
                        hideTopbar={configCheckout?.theme.settings.showTopbar === false}
                        trustpilotBusinessId={config?.trustpilotBusinessId}
                    />
                    <div className={$.content}>
                        {mustShowCountdown && !hasBookingCode && (
                            <div className={$.countdownPageWrapper}>
                                <CountDownTimer
                                    text={t('progresscard_order_reserved')}
                                    expiry={availability.expiryTime}
                                />
                            </div>
                        )}
                        <div className={$.left}>{children}</div>
                        {!mustHideTravelDetails && (
                            <div className={$.right}>
                                {availability && !hideCountDown && !hasBookingCode && (
                                    <>
                                        <div className={$.countDownWrapper}>
                                            <CountDownTimer
                                                text={t('progresscard_order_reserved')}
                                                expiry={availability.expiryTime}
                                            />
                                        </div>
                                        <VSpacer />
                                    </>
                                )}
                                <div className={$.progressCardWrapper}>
                                    <ProgressCard
                                        {...this.props}
                                        gateways={getGateways()}
                                        availability={availability}
                                        orderDetailOpen={orderDetailOpen}
                                        toggleDetail={this.toggleDetails}
                                        eventDetail={eventDetail}
                                        openHotelDetail={this.toggleHotelDetail}
                                        showPricing={showPricing}
                                        step={step}
                                        packageType={order?.packageType}
                                        leadTimePercentage={order?.downPaymentPercentage}
                                        leadTimeDays={order?.downPaymentLeadTimeDays}
                                        orderSelectedTicket={orderSelectedTicket}
                                        orderSelectedAccommodation={orderSelectedAccommodation}
                                        orderSelectedAccommodationIncludesBreakfast={
                                            orderSelectedAccommodationIncludesBreakfast
                                        }
                                        onRemoveDiscount={onRemoveDiscount}
                                        discountStatus={discountRemoveStatus}
                                        totalOrderPrice={totalOrderPrice}
                                        occupancy={Cache.getOccupancy()}
                                    />
                                </div>
                                <VSpacer />
                                <Card variant="inverted">
                                    <CardBody>
                                        <WhyPartner
                                            title={pageData.whyEquipo.title}
                                            values={pageData.whyEquipo.values}
                                            icon={pageData.whyEquipo.icon}
                                        />
                                        <>
                                            {hasMarketingCookieConsent() &&
                                                config?.trustpilotBusinessId && (
                                                    <>
                                                        <VSpacer size={SizeEnum.Small} />
                                                        <TrustpilotWidget
                                                            businessunitId={
                                                                config?.trustpilotBusinessId
                                                            }
                                                        />
                                                    </>
                                                )}
                                        </>
                                    </CardBody>
                                </Card>
                                <VSpacer />
                                <FaqBlockFeature />
                                <VSpacer />
                            </div>
                        )}
                    </div>

                    <div className="mobileStepperColumn">
                        <ProgressBar
                            active={pageData.stepIndicator.active}
                            steps={getProgressSteps(t, eventDetail && eventDetail.id)}
                        />
                    </div>

                    <Footer
                        trustpilotBusinessId={config?.trustpilotBusinessId}
                        gateways={
                            isOlympicsSeries(eventDetail?.serie.id || '')
                                ? getGateways().filter(
                                      (gateway) => gateway.name === olympicPreferredPaymentMethod
                                  )
                                : getGateways()
                        }
                    />
                </div>
                {eventDetail && (
                    <TravelDetail
                        open={orderDetailOpen}
                        occupancy={Cache.getOccupancy()}
                        discounts={discounts}
                        vouchers={vouchers}
                        packageType={order?.packageType}
                        gateways={getGateways()}
                        availability={availability}
                        eventDetail={eventDetail}
                        onClick={this.toggleDetails}
                        openHotelDetail={this.toggleHotelDetail}
                        step={step}
                        showPricing={showPricing === undefined ? true : showPricing}
                        noAccommodationOverlay={noAccommodationOverlay}
                        leadTimePercentage={config.leadTimePercentage}
                        leadTimeDays={config.leadTimeDays}
                        orderSelectedTicket={orderSelectedTicket}
                        orderSelectedAccommodation={orderSelectedAccommodation}
                        orderSelectedAccommodationIncludesBreakfast={
                            orderSelectedAccommodationIncludesBreakfast
                        }
                        onRemoveDiscount={onRemoveDiscount}
                        discountRemoveStatus={(key: string) => discountRemoveStatus[key]?.status}
                        totalOrderPrice={
                            orderSummary && orderSummary.priceTotal * orderSummary.numPax
                        }
                    />
                )}
                {config?.liveChatLicenseCode && (
                    <LiveChat licenseCode={config.liveChatLicenseCode.toString()} />
                )}

                {!mustHideTravelDetails && (
                    <div className={$.orderProgress}>
                        {availability && this.props.eventDetail && showPricing && (
                            <ProgressCardSmall
                                {...this.props}
                                availability={availability}
                                toggleDetail={this.toggleDetails}
                                packageType={order?.packageType}
                                orderSelectedTicket={orderSelectedTicket}
                                orderSelectedAccommodation={orderSelectedAccommodation}
                                orderSelectedAccommodationIncludesBreakfast={
                                    orderSelectedAccommodationIncludesBreakfast
                                }
                            />
                        )}
                    </div>
                )}
            </>
        );
    }
}

const Page = connect((state: RootState) => ({
    config: selectPartnerConfig(state),
    configCheckout: selectPartnerConfigCheckout(state),
    referrerUrl: selectReferrerUrl(state),
    availability: selectAvailability(state),
    order: selectOrder(state),
    orderSelectedTicket: selectSelectedTicket(state),
    orderSelectedAccommodation: selectSelectedAccommodation(state),
    orderSelectedAccommodationIncludesBreakfast:
        selectSelectedAccommodationIncludesBreakfast(state),
    showDiscountInput: selectDiscountInputOpen(state),
    discounts: selectDiscounts(state),
    vouchers: selectVouchers(state),
    discountStatus: selectDiscountStatus(state),
    discountRemoveStatus: selectDiscountRemoveStatus(state),
    orderSummary: selectOrderSummary(state),
    initializedSession: selectInitializedSession(state),
    session: selectSession(state),
}))(withRouter(withTranslation()(PageComponent)));

export default Page;
