import { Location } from 'history';
import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useNavigate } from 'src/app/hooks/use-navigate';
import { ConfirmBlocker } from './confirm-blocker';

type Props = {
    when: boolean;
    shouldBlockNavigation: (nextLocation: Location) => boolean;
    redirectTo: string;
    title: string;
    text: string;
    confirmText: string;
    cancelText: string;
};
// https://codesandbox.io/p/sandbox/custom-prompt-of-react-router-v5-lpcsv
export const RouteLeavingGuard = ({
    title,
    text,
    confirmText,
    cancelText,
    when,
    shouldBlockNavigation,
    redirectTo,
}: Props) => {
    const [modalVisible, updateModalVisible] = useState(false);
    const [lastLocation, updateLastLocation] = useState<Location | undefined>();
    const [confirmedNavigation, updateConfirmedNavigation] = useState(false);
    const navigate = useNavigate();

    const showModal = (location: Location) => {
        updateModalVisible(true);
        updateLastLocation(location);
    };

    const closeModal = (cb?: () => void) => {
        updateModalVisible(false);
        if (cb && typeof cb === 'function') {
            cb();
        }
    };

    const handleBlockedNavigation = (nextLocation: Location) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            showModal(nextLocation);
            return false;
        }
        return true;
    };
    const handleConfirmNavigationClick = () => {
        closeModal(() => {
            if (lastLocation) {
                updateConfirmedNavigation(true);
            }
        });
    };

    useEffect(() => {
        if (confirmedNavigation) {
            navigate(redirectTo || lastLocation?.pathname || '');
            updateConfirmedNavigation(false);
        }
    }, [confirmedNavigation]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <ConfirmBlocker
                text={text}
                title={title}
                confirmText={confirmText}
                cancelText={cancelText}
                visible={modalVisible}
                onCancel={closeModal}
                onConfirm={handleConfirmNavigationClick}
            />
        </>
    );
};
