import * as Sentry from '@sentry/react';
import BigNumber from 'bignumber.js';
import { Extra } from 'src/data/models/Availability';
import store from '../store';

const zeroBookingFee = {
    bookingFeeAmount: 0,
    id: '',
    bookingFeeTotal: 0,
};

/** Service fee is added only for B2C (per Feb 2025) */
export const calculateBookingFee = (pax: number) => {
    const isB2b = store.getState()?.app?.b2bMode;

    if (isB2b) return zeroBookingFee;

    const extras = (store.getState()?.order?.availabilityEntity?.extras as Extra[]) || [];
    const bookingFeeObject = extras.find((extra) => extra.product_type === 'BookingFee');

    const bookingFeeAmount = new BigNumber(bookingFeeObject?.price ?? 0).toNumber();

    if (isNaN(bookingFeeAmount)) {
        Sentry.captureException(
            'Booking fee amount is not a number. Returning default value of 0 booking fee',
            (scope) => {
                scope.setExtra('Booking fee object', bookingFeeObject);
                scope.setExtra('Booking fee amount', bookingFeeAmount);
                return scope;
            }
        );

        return zeroBookingFee;
    }

    return {
        bookingFeeAmount,
        id: bookingFeeObject?.id,
        bookingFeeTotal: new BigNumber(bookingFeeAmount).times(pax).toNumber(),
    };
};
