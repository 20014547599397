import classNames from 'classnames';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { countries } from 'src/app/constants/countries';
import { nationalities } from 'src/app/constants/nationalities';
import $ from 'src/app/pages/Payment/features/PaymentUserDetails/PaymentUserDetails.module.scss';
import { RowLabel } from 'src/app/pages/Payment/features/PaymentUserDetails/components/RowLabel';
import { selectBookOther, selectTravellerDetails } from 'src/app/store/TravellerDataSlice';
import { selectIsB2BMode } from 'src/app/store/appSlice';

type MainTravellerInformationProps = {
    expanded: boolean;
} & ComponentProps<'div'>;

export function MainTravellerInformation({
    className,
    expanded = false,
    ...props
}: MainTravellerInformationProps) {
    const isB2BMode = useSelector(selectIsB2BMode);
    const travellers = useSelector(selectTravellerDetails);
    const mainTraveller = travellers.find((traveller) => traveller.extended);
    const bookOther = useSelector(selectBookOther);
    const { t } = useTranslation();

    if (!mainTraveller) return null;

    const readableCountry = countries.find((country) => country.code === mainTraveller.country);
    const readableNationality = nationalities.find(
        (nationality) => nationality.code === mainTraveller.nationality
    );

    return (
        <div>
            <div className={$.tableHeading}>
                <strong>
                    {bookOther
                        ? t('details_booker', 'Booker')
                        : t('details_main_traveller_short', 'Main traveller')}
                </strong>
            </div>
            <div className={classNames($.informationGrid, $.showOnDesktop, className)} {...props}>
                <RowLabel>{t('details_name', 'Name')}</RowLabel>
                <div>
                    {mainTraveller.firstName} {mainTraveller.lastName}
                </div>
                {isB2BMode && (
                    <>
                        <RowLabel>{t('details_company_name', 'Company name')}</RowLabel>
                        <div>{mainTraveller.companyName}</div>
                    </>
                )}
                <RowLabel>{t('details_birthdate', 'Date of birth')}</RowLabel>
                <div>
                    {mainTraveller.dateOfBirth?.day}-{mainTraveller.dateOfBirth?.month}-
                    {mainTraveller.dateOfBirth?.year}
                </div>
                <RowLabel>{t('details_email', 'E-mail address')}</RowLabel>
                <div>{mainTraveller.email}</div>
                <RowLabel>{t('details_countrycode', 'Country')}</RowLabel>
                <div>{readableCountry?.name}</div>
                <RowLabel>{t('details_phonenumber', 'Telephone number')}</RowLabel>
                <div>{mainTraveller.phoneNumber}</div>
                <RowLabel>{t('details_nationality', 'Nationality')}</RowLabel>
                <div>{readableNationality?.name}</div>
            </div>
            <div className={classNames($.informationGrid, $.showOnMobile, className)} {...props}>
                <RowLabel>{t('details_name', 'Name')}</RowLabel>
                <div>
                    {mainTraveller.firstName} {mainTraveller.lastName}
                </div>
                <RowLabel>{t('details_email', 'E-mail address')}</RowLabel>
                <div>{mainTraveller.email}</div>
                {expanded && (
                    <>
                        {isB2BMode && (
                            <>
                                <RowLabel>{t('details_company_name', 'Company name')}</RowLabel>
                                <div>{mainTraveller.companyName}</div>
                            </>
                        )}

                        <RowLabel>{t('details_phonenumber', 'Telephone number')}</RowLabel>
                        <div>{mainTraveller.phoneNumber}</div>
                        <RowLabel>{t('details_birthdate', 'Date of birth')}</RowLabel>
                        <div>
                            {mainTraveller.dateOfBirth?.day}-{mainTraveller.dateOfBirth?.month}-
                            {mainTraveller.dateOfBirth?.year}
                        </div>
                        <RowLabel>{t('details_countrycode', 'Country')}</RowLabel>
                        <div>{readableCountry?.name}</div>
                        <RowLabel>{t('details_nationality', 'Nationality')}</RowLabel>
                        <div>{readableNationality?.name}</div>
                    </>
                )}
            </div>
        </div>
    );
}
