import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import Button from 'src/view/components/Button/Button';
import { MODAL_WIDTH, Modal, ModalBody } from 'src/view/components/Modal/Modal';
import $ from './confirm-blocker.module.scss';

type Props = {
    title: string;
    text: string;
    confirmText: string;
    cancelText: string;
    visible: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    className?: string;
    children?: React.ReactNode;
};

export const ConfirmBlocker = ({
    title,
    text,
    confirmText,
    cancelText,
    visible,
    onCancel,
    onConfirm,
}: Props) => {
    const confirm = () => {
        onCancel();
        onConfirm();
    };

    return (
        <>
            {visible && (
                <Modal
                    onClose={onCancel}
                    open={visible}
                    size={MODAL_WIDTH.SMALL}
                    ignoreFullscreenOnMobile
                >
                    <ModalBody>
                        <Box
                            display="flex"
                            flexDirection="column"
                            rowGap="4px"
                            className={$.confirmBlocker}
                        >
                            <Typography variant="body1" fontWeight="bold">
                                {title || ' Leave the page?'}
                            </Typography>
                            <Typography marginBottom="12px" variant="body2">
                                {text || 'Are you sure you want to leave this page?'}
                            </Typography>
                            <Button
                                text={cancelText || 'Stay on this page'}
                                fullWidth
                                onClick={onCancel}
                                className={$.button}
                            />
                            <button className={$.confirmButton} onClick={confirm}>
                                {confirmText || 'Leave'}
                            </button>
                        </Box>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};
