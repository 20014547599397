import AdyenCheckout from '@adyen/adyen-web';
import type Core from '@adyen/adyen-web/dist/types/core/core';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'src/app/hooks/use-navigate';
import { selectHasConsentedNewsLetter } from 'src/app/store/TravellerDataSlice';
import { selectIsB2BMode } from 'src/app/store/appSlice';
import { selectOrder, selectOrderSummary } from 'src/app/store/orderSlice';
import { KNOWN_ADYEN_IFRAME_ERROR_MESSAGE, getAdyenConfiguration } from 'src/app/utils/adyen';
import {
    pushPaymentMethodAddedEventToDataLayer,
    pushStepEventToDataLayer,
} from 'src/app/utils/googleAnalytics';
import { createAdyenPaymentSession, getOrder } from 'src/data/services/order';
import { useBookOrder } from './use-book-order';

/** Create an Adyen checkout instance for payment. */
export function useAdyenCheckout() {
    const order = useSelector(selectOrder);
    const orderSummary = useSelector(selectOrderSummary);
    const hasConsentedNewsLetter = useSelector(selectHasConsentedNewsLetter);
    const navigate = useNavigate();
    const { bookOrder } = useBookOrder();
    const isB2B = useSelector(selectIsB2BMode);

    const [attempt, setAttempt] = useState(0);
    const [orderId, setOrderId] = useState<string | null>(null);

    const { t, i18n } = useTranslation();
    const [checkout, setCheckout] = useState<Core>();
    const [error, setError] = useState<string | null>(null);
    const [isAlreadyBooked, setIsAlreadyBooked] = useState(false);
    // In order to prevent sending multiple requests because of re-rendering.
    const isSendingRequests = useRef(false);

    const getPaymentSession = useCallback(async () => {
        if (!order || isSendingRequests.current) return;
        isSendingRequests.current = true;

        try {
            const result = await bookOrder();

            if (result) setOrderId(result.orderId);
        } catch (err) {
            Sentry.captureException(err, {
                tags: {
                    component: 'use-adyen-checkout',
                    context: 'book enpoint',
                },
            });

            if (err instanceof AxiosError && err.response?.status === 400) {
                const { status } = await getOrder(order.id, order.secret, 'nl');
                const isAlreadyBooked = status === 'booked';
                if (isAlreadyBooked) {
                    isSendingRequests.current = false;
                    return setIsAlreadyBooked(true);
                }
            }

            isSendingRequests.current = false;
            return setError(t('payment_book_error'));
        }

        try {
            const response = await createAdyenPaymentSession(order.id, order.secret);
            const { session_id: sessionId, session_data: sessionData } = response;

            const checkoutObject = await AdyenCheckout(
                getAdyenConfiguration({
                    sessionId,
                    sessionData,
                    locale: i18n.language,
                    beforeSubmit: (data, _, actions) => {
                        pushPaymentMethodAddedEventToDataLayer(data.paymentMethod.type);
                        pushStepEventToDataLayer({
                            event_type: 'step_complete',
                            step_name: 'payment',
                            step_data: data.paymentMethod.type,
                        });

                        return actions.resolve(data);
                    },
                    onPaymentCompleted(data) {
                        const { resultCode } = data;
                        // Sentry.captureEvent({
                        //     message: 'IFrame - PaymentCompleted',
                        //     tags: {
                        //         resultCode,
                        //         from: 'useAdyenCheckout hook',
                        //     },
                        //     level: Sentry.Severity.Info,
                        // });
                        const queryString = new URLSearchParams({
                            orderId: order.id,
                            orderSecret: order.secret,
                            sessionId,
                            resultCode,
                        }).toString();

                        // Credit card doesn't have a redirect by default.
                        // We redirect manually.
                        navigate(`/completed?${queryString}`);
                    },
                    onError(error) {
                        if (error.name === 'CANCEL') {
                            // For payment methods that supports cancel, e.g. Paypal, `onError` is triggered in Adyen.
                            // We need to force-rerender to trigger checkout.update() to refresh the checkout UI.
                            setAttempt((current) => current + 1);
                        } else {
                            if (error.message !== KNOWN_ADYEN_IFRAME_ERROR_MESSAGE) {
                                Sentry.captureException(error, (scope) => {
                                    scope.setTag('origin', 'use-adyen-checkout.ts');

                                    return scope;
                                });
                            }
                            setError(
                                t('paymentLoadError', {
                                    defaultValue:
                                        'Failed to load Adyen payment options. Please refresh your page or try again later.',
                                })
                            );
                        }
                    },
                })
            );

            setCheckout(checkoutObject);
        } catch (error) {
            setError(String(error) || 'Something went wrong creating the payment.');
        } finally {
            isSendingRequests.current = false;
        }
    }, [hasConsentedNewsLetter, order, t]);

    useEffect(() => {
        getPaymentSession();
    }, [getPaymentSession]);

    return {
        /** The Adyen Checkout instance. */
        checkout,
        /** Error occurs during the Adyen payment flow, except "CANCEL". */
        error,
        /** A state that is used to rerender when the Adyen UI needs a refresh.
         * For example, when user closes the Paypal popup, which freezes the whole UI.
         */
        attempt,
        orderId,
        /** True if the order has status of 'booked'(paid) */
        isAlreadyBooked,
    };
}
