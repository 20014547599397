import BigNumber from 'bignumber.js';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import sumBy from 'lodash/sumBy';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { calculateBookingFee } from 'src/app/utils/calculate-booking-fee';
import { OrderTypeUtil } from 'src/app/utils/utils';
import { Accommodation } from 'src/data/models/Accommodation';
import { Availability } from 'src/data/models/Availability';
import { EventDetail } from 'src/data/models/EventDetail';
import { PackageType } from 'src/data/models/Order';
import { Ticket } from 'src/data/models/Ticket';
import * as Calculations from 'src/data/services/calculations';
import { CountUp } from 'src/view/components/CountUp/CountUp';
import * as Cache from '../../../data/services/cache';
import { getOccupancy } from '../../../data/services/cache';
import { ChevronDownIcon } from '../../../images/icons/ChevronDownIcon';
import $ from './ProgressCardSmall.module.scss';

interface Props extends WithTranslation {
    availability: Availability | null;
    eventDetail: EventDetail;
    toggleDetail: () => void;
    packageType: PackageType;
    orderSelectedTicket: Ticket | null;
    orderSelectedAccommodation: Accommodation | null;
    orderSelectedAccommodationIncludesBreakfast: boolean | null;
}

const ProgressCardSmall = (props: Props) => {
    const {
        t,
        toggleDetail,
        packageType,
        availability,
        orderSelectedTicket,
        orderSelectedAccommodation,
        orderSelectedAccommodationIncludesBreakfast,
    } = props;

    const [prevPrice, setPrevPrice] = useState<number | null>(null);

    const { adults } = Cache.getOccupancy();

    const occupancy = adults.length;

    const { totalPrice } = Calculations.getPrice(
        orderSelectedAccommodation?.id,
        availability,
        orderSelectedTicket?.id,
        packageType,
        orderSelectedAccommodationIncludesBreakfast || false
    );

    const roomAdults = sumBy(getOccupancy().roomLayout, (r) => r.adults);
    const roomChildren = sumBy(getOccupancy().roomLayout, (r) => r.children);
    const roomsOccupancy = roomAdults + roomChildren;

    const totalPackagePrice = OrderTypeUtil.hasHotel(packageType)
        ? totalPrice * roomsOccupancy
        : totalPrice * occupancy;

    const startY = 200;
    const animateDuration = 1;
    const totalPersons = OrderTypeUtil.hasHotel(packageType) ? roomsOccupancy : adults.length;
    const totalPriceWithBookingFee = new BigNumber(totalPackagePrice)
        .plus(calculateBookingFee(totalPersons).bookingFeeTotal)
        .toNumber();

    useEffect(() => {
        return () => {
            setPrevPrice(totalPriceWithBookingFee);
        };
    }, [totalPriceWithBookingFee]);

    const persons = OrderTypeUtil.hasHotel(packageType)
        ? totalPersons === 1
            ? t('traveldetail_totalfor_mobile_1', {
                  occupancy: roomAdults + roomChildren,
              })
            : t('traveldetail_totalfor_mobile', {
                  occupancy: roomAdults + roomChildren,
              })
        : totalPersons === 1
          ? t('traveldetail_totalfor_mobile_1', {
                occupancy: adults.length,
            })
          : t('traveldetail_totalfor_mobile', {
                occupancy: adults.length,
            });

    return (
        <motion.div animate={{ y: [startY, 0] }} transition={{ duration: animateDuration }}>
            <section className={classnames($.progressCard)}>
                <motion.div
                    animate={{ y: [startY, 0] }}
                    transition={{ delay: animateDuration, duration: 0.5 }}
                >
                    <div className={$.totalCosts}>
                        <CountUp
                            start={prevPrice || totalPriceWithBookingFee}
                            end={totalPriceWithBookingFee}
                            numberSpanProps={{ className: $.totalCosts }}
                        />
                    </div>

                    <span className={$.travelDetails}>{persons}</span>
                </motion.div>
                <motion.div
                    animate={{ y: [startY, 0] }}
                    transition={{ delay: animateDuration, duration: animateDuration }}
                    className={$.button}
                    onClick={toggleDetail}
                >
                    {t('traveldetails_see_all_details')}{' '}
                    <span className={$.chevronDown}>
                        <ChevronDownIcon />
                    </span>
                </motion.div>
            </section>
        </motion.div>
    );
};

export default withTranslation()(ProgressCardSmall);
