import { Divider } from '@mui/material';
import { default as classnames } from 'classnames';
import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { breakpoints } from 'src/app/constants/breakpoints';
import useWindowSize from 'src/app/hooks/UseWindowSize';
import { MainTravellerInformation } from 'src/app/pages/Payment/features/PaymentUserDetails/components/MainTravellerInformation';
import { TravellersInformation } from 'src/app/pages/Payment/features/PaymentUserDetails/components/TravellersInformation';
import { selectTravellerDetails } from 'src/app/store/TravellerDataSlice';
import { ChevronDownIcon } from 'src/images/icons/ChevronDownIcon';
import $ from './PaymentUserDetails.module.scss';

export function PaymentUserDetails() {
    const { windowSize } = useWindowSize();
    const [expanded, toggle] = useReducer((expanded) => !expanded, false);
    const allTravellers = useSelector(selectTravellerDetails);
    const { t } = useTranslation();

    const hasMoreThanOneTraveller = allTravellers.length > 1;

    return (
        <div className={$.informationStack}>
            <MainTravellerInformation expanded={expanded} />
            {expanded && hasMoreThanOneTraveller && <TravellersInformation />}
            {(hasMoreThanOneTraveller ||
                (windowSize.width && windowSize.width < breakpoints.medium)) && (
                <>
                    <Divider sx={{ margin: '0 0' }} />
                    <button className={$.collapseButton} onClick={toggle}>
                        <div>
                            {expanded
                                ? t('details_hide_all_traveller_details')
                                : t('details_show_all_traveller_details')}
                        </div>
                        <div className={classnames($.chevron, expanded && $.chevronOpen)}>
                            <ChevronDownIcon />
                        </div>
                    </button>
                </>
            )}
        </div>
    );
}
