import React from 'react';

export const SeatsIcon = () => (
    <svg
        data-name="Group 4418"
        xmlns="http://www.w3.org/2000/svg"
        width="24.754"
        height="27.996"
        viewBox="0 0 24.754 27.996"
    >
        <g data-name="Group 4349">
            <path
                data-name="Path 266"
                d="M10.221 153.489V147.4a2.4 2.4 0 0 0-2.4-2.4H4.215a2.4 2.4 0 0 0-2.4 2.4v6.088a2.4 2.4 0 0 0 .6 4.717h7.2a2.4 2.4 0 0 0 .6-4.717zm-7.2-6.088a1.2 1.2 0 0 1 1.2-1.2h3.602a1.2 1.2 0 0 1 1.2 1.2v6h-6zm6.6 9.6h-7.2a1.2 1.2 0 0 1 0-2.4h7.2a1.2 1.2 0 0 1 0 2.4z"
                transform="translate(-.018 -145)"
                style={{ fill: '#53b8e2' }}
            />
            <path
                data-name="Path 267"
                d="M180.887 153.489V147.4a2.4 2.4 0 0 0-2.4-2.4h-3.607a2.4 2.4 0 0 0-2.4 2.4v6.088a2.4 2.4 0 0 0 .6 4.717h7.2a2.4 2.4 0 0 0 .6-4.717zm-7.2-6.088a1.2 1.2 0 0 1 1.2-1.2h3.609a1.2 1.2 0 0 1 1.2 1.2v6h-6zm6.6 9.6h-7.2a1.2 1.2 0 0 1 0-2.4h7.2a1.2 1.2 0 0 1 0 2.4z"
                transform="translate(-157.931 -145)"
                style={{ fill: '#53b8e2' }}
            />
            <path
                data-name="Path 269"
                d="M68.266 179.133h2.4v1.2h-2.4z"
                transform="translate(-63.465 -176.732)"
                style={{ fill: '#53b8e2' }}
            />
            <path
                data-name="Path 270"
                d="M238.934 179.133h2.4v1.2h-2.4z"
                transform="translate(-221.381 -176.732)"
                style={{ fill: '#53b8e2' }}
            />
        </g>
        <g data-name="Group 4350">
            <path
                data-name="Path 266"
                d="M10.221 153.489V147.4a2.4 2.4 0 0 0-2.4-2.4H4.215a2.4 2.4 0 0 0-2.4 2.4v6.088a2.4 2.4 0 0 0 .6 4.717h7.2a2.4 2.4 0 0 0 .6-4.717zm-7.2-6.088a1.2 1.2 0 0 1 1.2-1.2h3.602a1.2 1.2 0 0 1 1.2 1.2v6h-6zm6.6 9.6h-7.2a1.2 1.2 0 0 1 0-2.4h7.2a1.2 1.2 0 0 1 0 2.4z"
                transform="translate(-.018 -130.21)"
                style={{ fill: '#53b8e2' }}
            />
            <path
                data-name="Path 267"
                d="M180.887 153.489V147.4a2.4 2.4 0 0 0-2.4-2.4h-3.607a2.4 2.4 0 0 0-2.4 2.4v6.088a2.4 2.4 0 0 0 .6 4.717h7.2a2.4 2.4 0 0 0 .6-4.717zm-7.2-6.088a1.2 1.2 0 0 1 1.2-1.2h3.609a1.2 1.2 0 0 1 1.2 1.2v6h-6zm6.6 9.6h-7.2a1.2 1.2 0 0 1 0-2.4h7.2a1.2 1.2 0 0 1 0 2.4z"
                transform="translate(-157.931 -130.21)"
                style={{ fill: '#53b8e2' }}
            />
            <path
                data-name="Path 269"
                d="M68.266 179.133h2.4v1.2h-2.4z"
                transform="translate(-63.465 -161.942)"
                style={{ fill: '#53b8e2' }}
            />
            <path
                data-name="Path 270"
                d="M238.934 179.133h2.4v1.2h-2.4z"
                transform="translate(-221.381 -161.942)"
                style={{ fill: '#53b8e2' }}
            />
        </g>
    </svg>
);
