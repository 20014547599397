import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CustomerSupportAssistOptions } from 'src/app/components/customer-support/CustomerSupportAssistOptions';
import { selectReferrerUrl } from 'src/app/store/appSlice';
import { Page } from 'src/view/components';
import Button from 'src/view/components/Button/Button';
import $ from './NotFound.module.scss';

interface Props {
    title?: string;
    text?: string;
    buttonText?: string;
    onButtonClick?: () => void;
}

export const NotFound: (props: Props) => JSX.Element = (props: Props) => {
    const { t } = useTranslation();
    const partnerReferrerUrl = useSelector(selectReferrerUrl);

    const { title, text, buttonText, onButtonClick } = props;

    const onDefaultButtonClick = () => {
        if (!partnerReferrerUrl) return;

        window.location.href = partnerReferrerUrl.toString();
    };

    return (
        <Page step={0} mustHideTravelDetails>
            <section className={$.root} data-cy="event-not-found">
                <h1 className={$.title}>
                    {title || t('eventNotFoundPage.title', 'Sorry, the event cannot be found')}
                </h1>
                <p className={$.text}>
                    {text ||
                        t(
                            'eventNotFoundPage.message',
                            'It seems this event is no longer bookable.'
                        )}
                </p>

                <div className={$.button}>
                    <Button
                        text={buttonText || t('eventNotFoundPage.buttonText', 'Go back to website')}
                        onClick={onButtonClick || onDefaultButtonClick}
                    />
                </div>

                <CustomerSupportAssistOptions />
            </section>
        </Page>
    );
};
