import Box from '@mui/material/Box/Box';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isOlympicsSeries, olympicPreferredPaymentMethod } from 'src/app/constants/olympics';
import {
    mapRemoveDiscountStatusToNotification,
    reduceWithDiscount,
} from 'src/app/core/lib/discount/discount';
import { DiscountStatusEnum, RemoveDiscountStatusType } from 'src/app/store/orderSlice';
import { calculateBookingFee } from 'src/app/utils/calculate-booking-fee';
import { getPriceByPercentage, isWithinTheLeadTimeDays, OrderTypeUtil } from 'src/app/utils/utils';
import { Accommodation } from 'src/data/models/Accommodation';
import { Availability } from 'src/data/models/Availability';
import { Discount } from 'src/data/models/Discount';
import { EventDetail } from 'src/data/models/EventDetail';
import { PackageType } from 'src/data/models/Order';
import { Ticket } from 'src/data/models/Ticket';
import * as Cache from 'src/data/services/cache';
import { getOccupancy } from 'src/data/services/cache';
import * as Calculations from 'src/data/services/calculations';
import { formatMoney } from 'src/data/services/formatting';
import { getGateways } from 'src/data/services/payments';
import { Body } from 'src/view/components/Body/Body';
import { DiscountOverview } from 'src/view/components/Discount';
import NotificationMessage from 'src/view/components/NotificationMessage/NotificationMessage';
import { PayIcons } from '..';
import $ from './TravelDetailList.module.scss';

interface Props extends WithTranslation {
    eventDetail: EventDetail;
    availability: Availability | null;
    extraIndent?: boolean;
    showPricing: boolean;
    step?: number;
    availabilityStatus: boolean;
    packageType: PackageType;
    leadTimeDays?: number;
    leadTimePercentage?: number;
    orderSelectedTicket: Ticket | null;
    orderSelectedAccommodation: Accommodation | null;
    orderSelectedAccommodationIncludesBreakfast: boolean | null;
    discounts: Discount[];
    vouchers: Discount[];
    onRemoveDiscount: (d: Discount | Discount[]) => Promise<void>;
    discountRemoveStatus: RemoveDiscountStatusType | undefined;
    totalOrderPrice: number;
}

interface State {}

// eslint-disable-next-line react/prefer-stateless-function
class OrderDetailList extends React.Component<Props, State> {
    // eslint-disable-next-line no-useless-constructor
    public constructor(props) {
        super(props);
    }

    public render() {
        const {
            t,
            showPricing,
            packageType,
            eventDetail,
            leadTimeDays,
            leadTimePercentage,
            availability,
            orderSelectedTicket,
            orderSelectedAccommodation,
            orderSelectedAccommodationIncludesBreakfast,
            discounts,
            onRemoveDiscount,
            discountRemoveStatus,
            vouchers,
            totalOrderPrice,
        } = this.props;

        const { adults } = Cache.getOccupancy();

        const footer = (
            <>
                <Body marginBottom={false} className={$.secure}>
                    <img src="/images/icon-lock.svg" alt="" />
                    {t('progresscard_booksafe')}
                </Body>
                <div className={$.payments}>
                    <PayIcons
                        gateways={
                            isOlympicsSeries(eventDetail?.serie.id || '')
                                ? getGateways().filter(
                                      (gateway) => gateway.name === olympicPreferredPaymentMethod
                                  )
                                : getGateways()
                        }
                        fullWidth
                        iconVariant={
                            isOlympicsSeries(eventDetail?.serie.id || '') ? 'large' : undefined
                        }
                    />
                </div>
            </>
        );

        if (!availability || !showPricing) {
            return (
                <>
                    <p className={$.noPrices}>{t('ticketConfirmPreferencestoseeprices')}</p>
                    {footer}
                </>
            );
        }

        const getTotalPeople = () => {
            const roomAdults = sumBy(getOccupancy().roomLayout, (r) => r.adults);
            const roomChildren = sumBy(getOccupancy().roomLayout, (r) => r.children);

            if (OrderTypeUtil.hasHotel(packageType)) {
                return roomAdults + roomChildren;
            }

            return adults.length;
        };

        const { bookingFeeAmount } = calculateBookingFee(getTotalPeople());

        const { price, accommodationSupplement, totalPrice } = Calculations.getPrice(
            orderSelectedAccommodation?.id,
            availability,
            orderSelectedTicket?.id,
            packageType,
            orderSelectedAccommodationIncludesBreakfast || false
        );

        const totalPriceWithBookingFee = totalPrice + bookingFeeAmount;

        if (getTotalPeople() === 0 || price === 0 || !orderSelectedTicket) {
            return null;
        }

        const ticketName =
            orderSelectedTicket && orderSelectedTicket.supplementPP > 0
                ? `${t('coupon_upgrade')} (${orderSelectedTicket.name})`
                : orderSelectedTicket.name;

        const mappedDiscountNotification = (function () {
            const hasInvalidDiscountCode = discountRemoveStatus
                ? Object.values(discountRemoveStatus).some(
                      (value) => value === DiscountStatusEnum.CODE_COULD_NOT_REMOVE
                  )
                : false;

            return hasInvalidDiscountCode
                ? mapRemoveDiscountStatusToNotification(DiscountStatusEnum.CODE_COULD_NOT_REMOVE, t)
                : undefined;
        })();

        const renderTravelDetail = (
            element: React.ReactNode,
            fontWeightMedium?: boolean,
            grey: boolean = true,
            className?: string,
            dataCy?: string
        ) => {
            return (
                <Body
                    small
                    fontWeight={fontWeightMedium ? 'medium' : undefined}
                    marginTop={false}
                    marginBottom={false}
                    grey={grey}
                    className={className}
                    dataCy={dataCy}
                >
                    {element}
                </Body>
            );
        };

        const totalCalculatedPrice =
            totalPriceWithBookingFee * getTotalPeople() +
            -reduceWithDiscount(totalOrderPrice, discounts, vouchers);

        return (
            <React.Fragment>
                <ul className={$.list}>
                    <li className={$.listItem}>
                        {OrderTypeUtil.hasHotel(packageType) &&
                            renderTravelDetail(
                                t('ticketHotelTicket'),
                                true,
                                true,
                                undefined,
                                'hotel-tickets-price'
                            )}

                        {OrderTypeUtil.onlyHasTicket(packageType) &&
                            renderTravelDetail(t('ticket'), true)}

                        {renderTravelDetail(
                            formatMoney(price.toString(), { hideZeroDecimals: true }),
                            true,
                            true,
                            undefined,
                            'standard-tickets-price'
                        )}
                    </li>
                    {orderSelectedTicket && (
                        <li className={$.listItem} data-cy="selected-seating-plan">
                            {renderTravelDetail(ticketName, true)}
                            {renderTravelDetail(
                                formatMoney(orderSelectedTicket.supplementPP.toString(), {
                                    hideZeroDecimals: true,
                                }),
                                true,
                                true,
                                undefined,
                                'category-upgrade-price-per-tickets'
                            )}
                        </li>
                    )}
                    {OrderTypeUtil.hasHotel(packageType) && orderSelectedAccommodation && (
                        <li className={$.listItem}>
                            {renderTravelDetail(orderSelectedAccommodation.name, true)}
                            {renderTravelDetail(
                                formatMoney(accommodationSupplement.toString(), {
                                    hideZeroDecimals: true,
                                })
                            )}
                        </li>
                    )}
                    {bookingFeeAmount !== 0 && (
                        <li className={$.listItem}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    columnGap: '4px',
                                }}
                            >
                                <Body
                                    small
                                    marginTop={false}
                                    marginBottom={false}
                                    grey
                                    fontWeight="medium"
                                >
                                    {t('bookingFee', 'Booking fee')}
                                </Body>
                            </Box>

                            {renderTravelDetail(
                                formatMoney(bookingFeeAmount.toString(), {
                                    hideZeroDecimals: true,
                                }),
                                true,
                                true,
                                undefined,
                                'booking-fee-price'
                            )}
                        </li>
                    )}
                </ul>
                <ul className={`${$.list} ${$.noPaddingtop}`}>
                    <li className={$.listItem}>
                        {renderTravelDetail(t('traveldetail_totalperperson'), true)}

                        {renderTravelDetail(
                            formatMoney(totalPriceWithBookingFee.toString(), {
                                hideZeroDecimals: true,
                            }),
                            true,
                            true,
                            undefined,
                            'total-per-person-price'
                        )}
                    </li>
                </ul>

                {(discounts.length > 0 || vouchers.length > 0) && (
                    <div className={$.discountContainer}>
                        <ul className={`${$.list} ${$.discountList}`}>
                            <li
                                className={$.alternativeListItem}
                                data-cy="discount-overview-travel-details"
                            >
                                <DiscountOverview
                                    label={t('ticket_discount_overview_title')}
                                    discounts={discounts}
                                    vouchers={vouchers}
                                    onRemove={onRemoveDiscount}
                                    discountRemoveStatus={discountRemoveStatus}
                                    totalOrderPrice={totalOrderPrice}
                                />
                            </li>
                        </ul>
                        {!!mappedDiscountNotification && (
                            <div className={$.discountError}>
                                <NotificationMessage
                                    severity={mappedDiscountNotification.type}
                                    message={mappedDiscountNotification.message}
                                />
                            </div>
                        )}
                    </div>
                )}

                <>
                    <div className={$.important}>
                        {renderTravelDetail(
                            getTotalPeople() === 1
                                ? t('traveldetail_totalfor_1', { occupancy: getTotalPeople() })
                                : t('traveldetail_totalfor', { occupancy: getTotalPeople() }),
                            true,
                            false
                        )}

                        <div className={$.totalCostsWrapper}>
                            <span className={$.totalCosts} data-cy="total-price">
                                {formatMoney(totalCalculatedPrice.toString(), {
                                    zeroDecimalReplace: '-',
                                })}
                            </span>
                        </div>
                        <Body marginTop={false} marginBottom={false} className={$.totalSubtitle}>
                            {t('traveldetails_nohiddencosts')}
                        </Body>
                    </div>
                    {leadTimeDays !== undefined &&
                        leadTimePercentage !== undefined &&
                        !isWithinTheLeadTimeDays(eventDetail.dateTime, leadTimeDays) &&
                        leadTimePercentage < 100 && (
                            <div className={$.paymentDetail}>
                                {renderTravelDetail(
                                    <>
                                        {t('traveldetails_paypercentage', {
                                            percentage: leadTimePercentage,
                                        })}

                                        <span className={$.percentagePrice}>
                                            {t('traveldetails_paypercentage_price', {
                                                price: formatMoney(
                                                    getPriceByPercentage(
                                                        totalCalculatedPrice,
                                                        leadTimePercentage
                                                    ).toString(),
                                                    { hideZeroDecimals: true }
                                                ),
                                            })}
                                        </span>
                                        {t('traveldetails_paypercentage_rest_amount', {
                                            durationBefore: moment
                                                .duration(leadTimeDays, 'days')
                                                .humanize({ w: 999 }),
                                        })}
                                    </>
                                )}
                            </div>
                        )}
                </>

                {footer}
            </React.Fragment>
        );
    }
}

export default withTranslation()(OrderDetailList);

const totalBookingFee = (numberOfPeople: number = 1) => {
    return numberOfPeople * 5;
};
