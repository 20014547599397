import classnames from 'classnames';
import { PropsWithChildren } from 'react';
import $ from './Card.module.scss';

export default ({
    children,
    bottomDivider = false,
    topDivider = false,
    className,
}: PropsWithChildren<{
    bottomDivider?: boolean;
    topDivider?: boolean;
    className?: string;
}>) => (
    <div
        className={classnames([
            $.body,
            bottomDivider && $.hasBottomDivider,
            topDivider && $.hasTopDivider,
            className,
        ])}
    >
        {children}
    </div>
);
