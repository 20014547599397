import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContactData } from 'src/app/constants/contactData';
import { selectIsB2BMode } from 'src/app/store/appSlice';
import { ContactPossiblitiesList } from 'src/app/views/contactPossibilties/ContactPossiblitiesList';
import $ from './CustomerSupportAssistOptions.module.scss'; // import the SCSS file

export const CustomerSupportAssistOptions = () => {
    const { t } = useTranslation();
    const isB2B = useSelector(selectIsB2BMode) || window.location.hostname.includes('b2b'); // double check, because we can't rely on the store if the server had an error (e.g. 500)

    return (
        <>
            <div className={$.assistSection}>
                {t('eventNotFoundPage.canWeAssistYou', 'Or can we assist you?')}
            </div>

            <ContactPossiblitiesList
                whatsappUrl={getContactData(t).whatsappUrl}
                phoneNumber={
                    isB2B ? getContactData(t).b2bPhoneNumberSupport : getContactData(t).phoneNumber
                }
                email={getContactData(t).b2bEmailSales} // we show email only for B2B
                config={{
                    hide: {
                        whatsapp: isB2B,
                        chat: isB2B,
                        email: !isB2B,
                    },
                    itemsBackgroundVariant: 'white',
                }}
            />
        </>
    );
};
