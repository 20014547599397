import classNames from 'classnames';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { nationalities } from 'src/app/constants/nationalities';
import $ from 'src/app/pages/Payment/features/PaymentUserDetails/PaymentUserDetails.module.scss';
import { RowLabel } from 'src/app/pages/Payment/features/PaymentUserDetails/components/RowLabel';
import { selectBookOther, selectTravellerDetails } from 'src/app/store/TravellerDataSlice';
import { selectIsB2BMode } from 'src/app/store/appSlice';

export function TravellersInformation({ className, ...props }: ComponentProps<'div'>) {
    const isB2BMode = useSelector(selectIsB2BMode);
    const allTravellers = useSelector(selectTravellerDetails);
    const travellers = allTravellers.filter((traveller) => !traveller.extended);
    const bookOther = useSelector(selectBookOther);
    const { t } = useTranslation();

    if (!travellers) return null;

    const incrementation = bookOther ? 1 : 2;

    return (
        <>
            {travellers.map((traveller, index) => {
                const readableNationality = nationalities.find(
                    (nationality) => nationality.code === traveller.nationality
                );

                return (
                    <div key={index}>
                        <div className={$.tableHeading}>
                            <strong>
                                {t('details_traveler', {
                                    traveler: index + incrementation,
                                })}
                            </strong>
                        </div>
                        <div
                            className={classNames($.informationGrid, $.showOnDesktop, className)}
                            {...props}
                        >
                            <RowLabel>{t('details_name', 'Name')}</RowLabel>
                            <div>
                                {traveller.firstName} {traveller.lastName}
                            </div>
                            {isB2BMode && (
                                <>
                                    <RowLabel>{t('details_company_name', 'Company name')}</RowLabel>
                                    <div>{traveller.companyName}</div>
                                </>
                            )}
                            <RowLabel>{t('details_birthdate', 'Date of birth')}</RowLabel>

                            <div>
                                {traveller.dateOfBirth?.day}-{traveller.dateOfBirth?.month}-
                                {traveller.dateOfBirth?.year}
                            </div>
                            <RowLabel>{t('details_nationality', 'Nationality')}</RowLabel>
                            <div>{readableNationality?.name}</div>
                        </div>
                        <div
                            className={classNames($.informationGrid, $.showOnMobile, className)}
                            {...props}
                        >
                            <RowLabel>{t('details_name', 'Name')}</RowLabel>
                            <div>
                                {traveller.firstName} {traveller.lastName}
                            </div>
                            {isB2BMode && (
                                <>
                                    <RowLabel>{t('details_company_name', 'Company name')}</RowLabel>
                                    <div>{traveller.companyName}</div>
                                </>
                            )}
                            <RowLabel>{t('details_birthdate', 'Date of birth')}</RowLabel>
                            <div>
                                {traveller.dateOfBirth?.day}-{traveller.dateOfBirth?.month}-
                                {traveller.dateOfBirth?.year}
                            </div>
                            <RowLabel>{t('details_nationality', 'Nationality')}</RowLabel>
                            <div>{readableNationality?.name}</div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
