import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CustomerSupportAssistOptions } from 'src/app/components/customer-support/CustomerSupportAssistOptions';
import { appConstants } from 'src/app/constants/app';
import { selectReferrerUrl } from 'src/app/store/appSlice';
import { ReloadIcon } from 'src/images/icons/steps/ReloadIcon';
import { Page } from 'src/view/components';
import Button from 'src/view/components/Button/Button';
import $ from './ServiceUnavailable.module.scss';

interface Props {
    title?: string;
    text?: string;
    buttonText?: string;
    onButtonClick?: () => void;
}

export const ServiceUnavailable: (props: Props) => JSX.Element = (props: Props) => {
    const { t } = useTranslation();
    const partnerReferrerUrl = useSelector(selectReferrerUrl);

    const onClick = () => {
        const link = partnerReferrerUrl
            ? partnerReferrerUrl.toString()
            : appConstants.fallbackWebsite;

        window.location.href = link;
    };

    return (
        <Page step={0} mustHideTravelDetails>
            <section className={$.root} data-cy="event-not-found">
                <h1 className={$.title}>
                    {t('serviceUnavailable.title', 'Uh oh... Something went wrong on our end')}
                </h1>
                <p className={$.text}>
                    {t(
                        'serviceUnavailable.message',
                        'Please wait a few seconds and try refreshing the page, as the issue might resolve itself quickly and you can proceed with your order.'
                    )}
                </p>

                <div className={$.buttonsContainer}>
                    <div className={$.button}>
                        <Button
                            text="Refresh"
                            onClick={() => window.location.reload()}
                            fullWidth
                            iconComponent={<ReloadIcon />}
                            alignIcon="right"
                            iconClassName={$.buttonIcon}
                            className={$.button}
                        >
                            <ReloadIcon />
                        </Button>
                    </div>

                    <div className={$.button}>
                        <Button
                            text={t('eventNotFoundPage.buttonText', 'Go back to website')}
                            onClick={onClick}
                            variant="outlined"
                            fullWidth
                            className={$.backToWebsiteBtn}
                        />
                    </div>
                    <CustomerSupportAssistOptions />
                </div>
            </section>
        </Page>
    );
};
