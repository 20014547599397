import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FieldError, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAbExperiment } from 'src/ab-testing/experiments';
import { fonts } from 'src/app/constants/fonts';
import { getNationalityByCode } from 'src/app/constants/nationalities';
import { colors } from 'src/app/constants/theme';
import getTravelerDetailsFormValidationScheme from 'src/app/forms/TravellerDetails/TravellerDetailsFormValidation';
import {
    mapCountriesToDropdownOptions,
    mapNationalitiesToDropdownOptions,
} from 'src/app/forms/TravellerDetails/dropdownOptions';
import TravellerFormModel, { TravellerBirthDate } from 'src/app/models/TravellerFormModel';
import { TravellerError } from 'src/app/pages/Details/types';
import { RootState, useTypedDispatch } from 'src/app/store';
import {
    selectBookOther,
    selectBookerDetails,
    selectHasConsentedNewsLetter,
    selectTravellerDetails,
    setBookOther,
    setBookerDetails,
    setBookerEmail,
    setConsentedNewsletter,
    setTravellersDetails,
    setUpdatedAt,
} from 'src/app/store/TravellerDataSlice';
import { selectIsB2BMode, selectSession } from 'src/app/store/appSlice';
import { selectEventDetail } from 'src/app/store/eventSlice';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { getTranslatedCountryByCode } from 'src/app/utils/locale';
import { parseLink } from 'src/app/utils/utils';
import { Restrictions } from 'src/data/models/EventDetail';
import { ReloadIcon } from 'src/images/icons/steps/ReloadIcon';
import { getMicroCopyVariant } from 'src/view/ABComponents/TCO504MicroCopyText/TCO504MicroCopyText';
import { Checkbox, ErrorMessage, FormInput, FormSelect } from 'src/view/components';
import { Body } from 'src/view/components/Body/Body';
import Card, { CardBody } from 'src/view/components/Card';
import ConfirmButton from 'src/view/components/ConfirmButton/ConfirmButton';
import FieldErrorMessage from 'src/view/components/Form/FieldErrorMessage';
import { FormDateInput } from 'src/view/components/FormDateSelect/FormDateInput';
import FormHintMessage from 'src/view/components/FormHintMessage/FormHintMessage';
import { FormInputSize } from 'src/view/components/FormInput/FormInput';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';
import { Heading } from 'src/view/components/Heading/Heading';
import { VSpacer } from 'src/view/components/Page';
import { PhoneNumberSelect } from 'src/view/components/PhoneNumberSelect/PhoneNumberSelect';
import Spinner from 'src/view/components/Spinner';
import { DiscountsFeature } from 'src/view/features/discounts/DiscountsFeature';
import travellerDetailsCss from './TravellerDetailsForm.module.scss';
import { EmailErrorMessage } from './email-error-message';
import { EmptyRow } from './emptry-row';
import { EmptyBlock } from './empty-block';
import { useVerifyEmail } from './use-verify-email';

const useStyles = makeStyles((theme) => ({
    line: {
        border: 0,
        borderTop: `1px solid ${colors.lightStone}`,
    },
    errorDetailName: {
        color: colors.blue,
        fontWeight: 400,
    },
    errorHeading: {
        marginTop: 0,
        display: 'block',
    },
    errorBookerName: {
        fontSize: '.875rem',
        fontWeight: 'bold',
        display: 'block',
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    checkbox: {
        marginBottom: '1rem',
    },
    resetTravellerInfo: {
        flexGrow: 0,
        textAlign: 'right',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontSize: '.875rem',
        '&:hover': {
            cursor: 'pointer',
        },
        '& svg': {
            height: '.875rem',
            width: '.875rem',
            marginRight: theme.spacing(1),
        },
    },
    headingWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3),
    },
    resetWrapper: {
        display: 'block',
        textAlign: 'right',
    },
    notMatchingError: {
        margin: theme.spacing(2, 0),
    },
    radioLabel: {
        '& .MuiFormControlLabel-label': {
            fontFamily: fonts.normal,
            letterSpacing: 0,
            lineHeight: 0,
        },
        '& .MuiRadio-root': {
            '&:hover': {
                background: 'transparent',
            },
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    checkboxContainer: {
        marginTop: theme.spacing(1.25),
    },
    restrictionsDescriptions: {
        marginTop: theme.spacing(1),
    },
    restrictionsList: {
        padding: 15,
        margin: 0,
        '& li:not(:last-of-type)': {
            marginBottom: theme.spacing(0.5),
        },
    },
    emailLoadingSpinner: {
        marginLeft: theme.spacing(1),
    },
}));

export interface TravellerDetailsFormValues {
    travellers: TravellerFormModel[];
    restrictionsConfirmed?: boolean;
}

interface TravellerDetailsFormProps {
    travellerDataRequired?: boolean;
    numberOfAdults?: number;
    numberOfChildren?: number;
    eventDate: Moment;
    restrictions?: Restrictions;
    awayTeam?: string;
    onSubmit: (values: TravellerDetailsFormValues, bookOther: boolean) => void;
    travellerErrors?: TravellerError[];
    customerErrors?: string[];
    notMatchingError?: (
        values: TravellerDetailsFormValues,
        bookOther: boolean
    ) => string | undefined;
    validationErrors?: string[];
    onValuesChanged?: (values: TravellerDetailsFormValues) => void;
}

export const getBookerNameAndSubHeader = (bookOther: boolean, travellerIndex = 0, t: TFunction) => {
    if (bookOther && travellerIndex === 0)
        return {
            name: t('details_customerdetails'),
            subHeader: t('details_customerDetails_subHeader'),
        };

    if (!bookOther && travellerIndex === 0)
        return {
            name: t('details_main_traveller'),
            subHeader: t('details_main_traveller_subheader'),
        };

    if (bookOther && travellerIndex === 1)
        return {
            name: t('details_who_goes_to_event'),
            subHeader: t('details_who_goes_to_event_subHeader'),
        };

    const nameNumberAddition = !bookOther ? 1 : 0;

    return { name: t('details_traveler', { traveler: travellerIndex + nameNumberAddition }) };
};

const TravellerDetailsForm = ({
    travellerDataRequired,
    numberOfAdults = 1,
    numberOfChildren = 0,
    eventDate,
    awayTeam,
    restrictions,
    onSubmit,
    travellerErrors = [],
    customerErrors = [],
    notMatchingError,
    validationErrors = [],
    onValuesChanged,
}: TravellerDetailsFormProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useTypedDispatch();

    const bookOtherFromCache = useSelector(selectBookOther);
    const bookerDetails = useSelector(selectBookerDetails);
    const travellerDetails = useSelector(selectTravellerDetails);
    const isB2BMode = useSelector(selectIsB2BMode);
    const microCopyVariant = useSelector((state: RootState) => getMicroCopyVariant(state));
    const session = useSelector(selectSession);
    const eventDetail = useSelector(selectEventDetail);
    const hasConsentedNewsLetter = useSelector(selectHasConsentedNewsLetter);

    const [internalBookOther, setInternalBookOther] = useState(isB2BMode || bookOtherFromCache);
    const validateFormOnBlur = useSelector((state: RootState) =>
        getAbExperiment(state, 'tco582', 'fieldValidationOnBlurWithState')
    );

    const { control, handleSubmit, reset, watch, setValue, getValues } =
        useForm<TravellerDetailsFormValues>({
            mode: validateFormOnBlur ? 'onBlur' : 'onChange',
            resolver: yupResolver(
                getTravelerDetailsFormValidationScheme({
                    t,
                    eventDate,
                    restrictions,
                    isB2b: isB2BMode,
                })
            ),
        });

    const {
        fields: travellers,
        append,
        update,
    } = useFieldArray({
        control,
        name: 'travellers',
    });

    const { isSubmitting, errors: formErrors } = useFormState({
        control,
    });

    const hasBlacklistedCountries =
        restrictions?.blacklistedCountries && restrictions?.blacklistedCountries.length > 0;
    const hasBlacklistedNationalities =
        restrictions?.blacklistedNationalities && restrictions?.blacklistedNationalities.length > 0;

    const eventHasRestrictions =
        hasBlacklistedNationalities || hasBlacklistedCountries || !restrictions?.allowAwayFans;

    function writeFormValuesToRedux() {
        const clonedBookerDetails = cloneDeep(getValues().travellers[0]);
        const clonedTravellerDetails = cloneDeep(getValues().travellers);

        dispatch(setUpdatedAt(moment()));
        dispatch(setBookerDetails(clonedBookerDetails));
        dispatch(setTravellersDetails(clonedTravellerDetails));

        onValuesChanged?.(getValues());
    }

    const debouncedWriteToRedux = debounce(writeFormValuesToRedux, 500, { maxWait: 2000 });

    const emailValue = watch('travellers.0.email');

    const { emailErrors, hasEmailError, emailIsValidating, resetEmailErrors } =
        useVerifyEmail(emailValue);

    useEffect(() => {
        let travellerFormIndex = 0;

        if (travellers.length > 0) {
            reset();
        }

        if (internalBookOther) {
            append({
                ...bookerDetails,
                type: 'booker',
                extended: true,
            });
            travellerFormIndex++;
        }

        if (!travellerDataRequired) {
            append({
                ...travellerDetails[travellerFormIndex],
                type: !internalBookOther ? 'booker' : 'adult',
                extended: !internalBookOther,
            });

            travellerFormIndex++;

            return;
        }

        // will only come here if travellerDataRequired
        Array.from({ length: numberOfAdults }).forEach((_, i) => {
            append({
                ...travellerDetails[i + travellerFormIndex],
                extended: !internalBookOther && i === 0,
                type: 'adult',
            });
        });

        Array.from({ length: numberOfChildren }).forEach((_, i) => {
            append({
                ...travellerDetails[i + travellerFormIndex + numberOfAdults],
                type: 'child',
            });
        });
    }, [numberOfAdults, numberOfChildren, internalBookOther]);

    const selectSuggestion = useCallback(
        (suggestion: string) => {
            resetEmailErrors();
            setValue('travellers.0.email', suggestion);
            dispatch(setBookerEmail(suggestion));
        },
        [resetEmailErrors]
    );

    const resetTraveller = (index: number, traveller: TravellerFormModel) => {
        update(index, {
            extended: traveller.extended,
            type: traveller.type,
        });

        writeFormValuesToRedux();
    };

    const hasError = (backendErrors: string[] = [], name: string, error?: FieldError) =>
        !!error || backendErrors.includes(name);

    const getDateOfBirthErrors = (index: number) => formErrors.travellers?.[index]?.dateOfBirth;
    const birthdayErrorFilter: string[] = ['min', 'max', 'isPersonAbove18', 'isPersonUnder120'];
    const shouldShowError = (type?: string, value?: TravellerBirthDate) => {
        if (!type) return true;

        if (!value?.day || !value?.month || !value?.year) return false;

        return birthdayErrorFilter.includes(type);
    };

    const matchingError = notMatchingError?.(getValues(), internalBookOther);

    const getRestrictionsCheckboxText = () =>
        eventDetail?.organizer?.hasTermsAndConditions ? (
            <div
                dangerouslySetInnerHTML={{
                    __html: parseLink(
                        t('details_restrictions_confirmation', {
                            url: eventDetail?.organizer?.termsAndConditionsUrl,
                        }),
                        true
                    ),
                }}
            />
        ) : (
            <span>{t('details_restrictions_confirmation_without_url')}</span>
        );

    /** Gives back the pointer to the first available error field in any of the travellers errors
     * We must support the null values to to correctly point to index of the traveller
     * From : [
            null,
            {
                "firstName": {...},
                 "lastName": {...}
            },
            {
                "nationality": {...},
            }
        ]
     * To : travellers.1.firstName
     * @returns travellers.[index].key or empty string */
    const getFirstErrorFieldPointer = useCallback(
        (travellersErrors: (typeof formErrors)['travellers']) => {
            const firstError =
                travellersErrors
                    ?.map((traveller, index) => {
                        const keys = Object.keys(traveller);
                        const firstKey = keys.length > 0 ? keys[0] : null;
                        return firstKey ? `travellers.${index}.${firstKey}` : null;
                    })
                    .filter(Boolean) || [];
            return firstError[0] || '';
        },
        []
    );

    useEffect(() => {
        if (formErrors?.travellers?.length) {
            const targetError = getFirstErrorFieldPointer(formErrors.travellers);
            const domElement = document.getElementById(targetError);
            if (domElement) {
                domElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [formErrors, getFirstErrorFieldPointer]);

    return (
        <>
            {(travellerErrors.length > 0 || customerErrors.length > 0) && (
                <ErrorMessage
                    content={
                        <>
                            <span className={classes.errorHeading}>
                                {t('details_info_not_valid')}
                            </span>
                            {customerErrors.length > 0 && internalBookOther && (
                                <>
                                    <span className={classNames(classes.errorBookerName)}>
                                        {getBookerNameAndSubHeader(internalBookOther, 0, t).name}
                                    </span>
                                    <FieldErrorLabels errors={customerErrors} />
                                </>
                            )}
                            {travellerErrors.map((e) => (
                                <React.Fragment key={`error-${e.nTraveller}`}>
                                    <span className={classes.errorBookerName}>
                                        {
                                            getBookerNameAndSubHeader(
                                                internalBookOther,
                                                internalBookOther ? e.nTraveller + 1 : e.nTraveller,
                                                t
                                            ).name
                                        }
                                    </span>
                                    <FieldErrorLabels errors={e?.errors || []} />
                                </React.Fragment>
                            ))}
                        </>
                    }
                />
            )}
            {travellers.map((traveller, travellerIndex) => {
                const travellerError = internalBookOther
                    ? travellerErrors[travellerIndex + 1]
                    : travellerErrors[travellerIndex];

                return (
                    <section key={traveller.id}>
                        <Card className="fullWidthCard">
                            <CardBody>
                                <Grid
                                    container
                                    spacing={3}
                                    data-cy={`details-form-${travellerIndex + 1}`}
                                >
                                    {traveller.extended && (
                                        <>
                                            <Grid item xs={12}>
                                                <Heading
                                                    variant="h3"
                                                    grey
                                                    marginTop={false}
                                                    marginBottom={false}
                                                >
                                                    {t('details_booking')}
                                                </Heading>
                                            </Grid>
                                            <Grid item xs={12} data-cy="traveler-selection">
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={internalBookOther}
                                                    onChange={(e) => {
                                                        const castedValue =
                                                            e.target.value === 'true';
                                                        setInternalBookOther(castedValue);
                                                        dispatch(setBookOther(castedValue));
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        className={classes.radioLabel}
                                                        value={false}
                                                        control={
                                                            <Radio
                                                                value={false}
                                                                disableRipple
                                                                disableFocusRipple
                                                                disableTouchRipple
                                                                color="success"
                                                            />
                                                        }
                                                        label={t('details_bookme')}
                                                    />
                                                    <FormControlLabel
                                                        className={classes.radioLabel}
                                                        value={true}
                                                        control={
                                                            <Radio
                                                                value={true}
                                                                disableRipple
                                                                disableFocusRipple
                                                                disableTouchRipple
                                                                color="success"
                                                            />
                                                        }
                                                        label={t('details_bookother')}
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <hr className={classes.line} />
                                            </Grid>
                                        </>
                                    )}

                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.headingWrapper}
                                        data-cy="traveler-details-header"
                                    >
                                        <Heading
                                            variant="h2"
                                            marginTop={false}
                                            marginBottom={false}
                                        >
                                            {
                                                getBookerNameAndSubHeader(
                                                    internalBookOther,
                                                    travellerIndex,
                                                    t
                                                ).name
                                            }
                                        </Heading>
                                        <div
                                            className={classNames([
                                                classes.resetTravellerInfo,
                                                travellerDetailsCss.resetTravellerInfo,
                                            ])}
                                            onClick={() =>
                                                resetTraveller(travellerIndex, traveller)
                                            }
                                            data-cy="reset-details-form"
                                        >
                                            <ReloadIcon />
                                            <span>{t('details_reset')}</span>
                                        </div>
                                    </Grid>
                                    {getBookerNameAndSubHeader(internalBookOther, travellerIndex, t)
                                        .subHeader && (
                                        <Grid item xs={12} style={{ paddingTop: 8 }}>
                                            {
                                                getBookerNameAndSubHeader(
                                                    internalBookOther,
                                                    travellerIndex,
                                                    t
                                                ).subHeader
                                            }
                                        </Grid>
                                    )}

                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        data-cy="details-first-name"
                                        id={`travellers.${travellerIndex}.firstName`}
                                    >
                                        <FormLabel>{t('details_firstname')}*</FormLabel>
                                        <Controller
                                            name={`travellers.${travellerIndex}.firstName`}
                                            control={control}
                                            render={({
                                                field: { name, onChange, value, onBlur },
                                                fieldState: { error, isTouched },
                                            }) => (
                                                <>
                                                    <FormInput
                                                        success={
                                                            validateFormOnBlur &&
                                                            isTouched &&
                                                            !error
                                                        }
                                                        hasError={hasError(
                                                            travellerError?.errors,
                                                            'first_name',
                                                            error
                                                        )}
                                                        name={name}
                                                        size={FormInputSize.Medium}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            debouncedWriteToRedux();
                                                        }}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        fullWidth
                                                    />
                                                    <FieldErrorMessage message={error?.message} />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        data-cy="details-last-name"
                                        id={`travellers.${travellerIndex}.lastName`}
                                    >
                                        <FormLabel>{t('details_lastname')}*</FormLabel>
                                        <Controller
                                            name={`travellers.${travellerIndex}.lastName`}
                                            control={control}
                                            render={({
                                                field: { name, onChange, value, onBlur },
                                                fieldState: { error, isTouched },
                                            }) => (
                                                <>
                                                    <FormInput
                                                        success={
                                                            validateFormOnBlur &&
                                                            isTouched &&
                                                            !error
                                                        }
                                                        hasError={hasError(
                                                            travellerError?.errors,
                                                            'last_name',
                                                            error
                                                        )}
                                                        name={name}
                                                        size={FormInputSize.Medium}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            debouncedWriteToRedux();
                                                        }}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        fullWidth
                                                    />
                                                    <FieldErrorMessage message={error?.message} />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    {isB2BMode && (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                id={`travellers.${travellerIndex}.companyName`}
                                            >
                                                <FormLabel>{t('details_company_name')}*</FormLabel>
                                                <Controller
                                                    name={`travellers.${travellerIndex}.companyName`}
                                                    control={control}
                                                    render={({
                                                        field: { name, onChange, value, onBlur },
                                                        fieldState: { error, isTouched },
                                                    }) => (
                                                        <>
                                                            <FormInput
                                                                success={
                                                                    validateFormOnBlur &&
                                                                    isTouched &&
                                                                    !error
                                                                }
                                                                name={name}
                                                                size={FormInputSize.Medium}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    debouncedWriteToRedux();
                                                                }}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                fullWidth
                                                            />
                                                            <FieldErrorMessage
                                                                message={error?.message}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <EmptyBlock />
                                        </>
                                    )}

                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        data-cy="details-nationality"
                                        id={`travellers.${travellerIndex}.nationality`}
                                    >
                                        <FormLabel>{t('details_nationality')}*</FormLabel>
                                        <Controller
                                            name={`travellers.${travellerIndex}.nationality`}
                                            control={control}
                                            render={({
                                                field: { name, onChange, value, onBlur },
                                                fieldState: { error },
                                            }) => (
                                                <>
                                                    <FormSelect
                                                        hasError={hasError(
                                                            travellerError?.errors,
                                                            'nationality',
                                                            error
                                                        )}
                                                        options={mapNationalitiesToDropdownOptions()}
                                                        onChange={(value) => {
                                                            onChange({
                                                                target: {
                                                                    name,
                                                                    value,
                                                                },
                                                            });

                                                            debouncedWriteToRedux();
                                                        }}
                                                        onBlur={onBlur}
                                                        value={value}
                                                        placeholder={t(
                                                            'form_select_nationality_placeholder'
                                                        )}
                                                    />
                                                    <FieldErrorMessage message={error?.message} />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <EmptyBlock />
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        data-cy="details-birthday"
                                        id={`travellers.${travellerIndex}.dateOfBirth`}
                                    >
                                        <FormLabel>
                                            {t('details_birthdate')}
                                            {traveller.extended ? ' (>18)' : ''}*
                                        </FormLabel>
                                        <Controller
                                            name={`travellers.${travellerIndex}.dateOfBirth`}
                                            control={control}
                                            render={({
                                                field: { onChange, value, onBlur },
                                                fieldState: { error, isTouched },
                                            }) => (
                                                <>
                                                    <FormDateInput
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            debouncedWriteToRedux();
                                                        }}
                                                        onBlur={onBlur}
                                                        day={value?.day}
                                                        month={value?.month}
                                                        year={value?.year}
                                                        dayError={
                                                            !!getDateOfBirthErrors(travellerIndex)
                                                                ?.day || !!error?.message
                                                        }
                                                        daySuccess={
                                                            isTouched &&
                                                            !getDateOfBirthErrors(travellerIndex)
                                                                ?.day &&
                                                            !!value?.day &&
                                                            !error?.message
                                                        }
                                                        monthError={
                                                            !!getDateOfBirthErrors(travellerIndex)
                                                                ?.month || !!error?.message
                                                        }
                                                        monthSuccess={
                                                            isTouched &&
                                                            !getDateOfBirthErrors(travellerIndex)
                                                                ?.month &&
                                                            !!value?.month &&
                                                            !error?.message
                                                        }
                                                        yearError={
                                                            !!getDateOfBirthErrors(travellerIndex)
                                                                ?.year || !!error?.message
                                                        }
                                                        yearSuccess={
                                                            isTouched &&
                                                            !getDateOfBirthErrors(travellerIndex)
                                                                ?.year &&
                                                            !!value?.year &&
                                                            !error?.message
                                                        }
                                                    />

                                                    {shouldShowError(
                                                        getDateOfBirthErrors(travellerIndex)?.day
                                                            ?.type,
                                                        value
                                                    ) && (
                                                        <FieldErrorMessage
                                                            message={
                                                                getDateOfBirthErrors(travellerIndex)
                                                                    ?.day?.message
                                                            }
                                                        />
                                                    )}

                                                    {shouldShowError(
                                                        getDateOfBirthErrors(travellerIndex)?.month
                                                            ?.type,
                                                        value
                                                    ) && (
                                                        <FieldErrorMessage
                                                            message={
                                                                getDateOfBirthErrors(travellerIndex)
                                                                    ?.month?.message
                                                            }
                                                        />
                                                    )}

                                                    {shouldShowError(
                                                        getDateOfBirthErrors(travellerIndex)?.year
                                                            ?.type,
                                                        value
                                                    ) && (
                                                        <FieldErrorMessage
                                                            message={
                                                                getDateOfBirthErrors(travellerIndex)
                                                                    ?.year?.message
                                                            }
                                                        />
                                                    )}

                                                    {shouldShowError(error?.type, value) && (
                                                        <FieldErrorMessage
                                                            message={error?.message}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        />
                                    </Grid>

                                    {traveller.extended && (
                                        <>
                                            {restrictions?.requireFullCustomerAddress ? (
                                                <>
                                                    <EmptyBlock />
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        data-cy="details-address-1"
                                                        id={`travellers.${travellerIndex}.addressLine1`}
                                                    >
                                                        <FormLabel>
                                                            {t('details_address_line_1')}*
                                                        </FormLabel>
                                                        <Controller
                                                            name={`travellers.${travellerIndex}.addressLine1`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    name,
                                                                    onChange,
                                                                    value,
                                                                    onBlur,
                                                                },
                                                                fieldState: { error, isTouched },
                                                            }) => (
                                                                <>
                                                                    <FormInput
                                                                        success={
                                                                            validateFormOnBlur &&
                                                                            isTouched &&
                                                                            !error
                                                                        }
                                                                        hasError={hasError(
                                                                            travellerErrors[
                                                                                travellerIndex
                                                                            ]?.errors,
                                                                            name,
                                                                            error
                                                                        )}
                                                                        name={name}
                                                                        size={FormInputSize.Medium}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                            debouncedWriteToRedux();
                                                                        }}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        fullWidth
                                                                    />
                                                                    <FieldErrorMessage
                                                                        message={error?.message}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <EmptyBlock />
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        data-cy="details-address-2"
                                                        id={`travellers.${travellerIndex}.addressLine2`}
                                                    >
                                                        <FormLabel>
                                                            {t('details_address_line_2')}
                                                        </FormLabel>
                                                        <Controller
                                                            name={`travellers.${travellerIndex}.addressLine2`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    name,
                                                                    onChange,
                                                                    value,
                                                                    onBlur,
                                                                },
                                                                fieldState: { error, isTouched },
                                                            }) => (
                                                                <>
                                                                    <FormInput
                                                                        success={
                                                                            validateFormOnBlur &&
                                                                            isTouched &&
                                                                            !error
                                                                        }
                                                                        hasError={hasError(
                                                                            travellerErrors[
                                                                                travellerIndex
                                                                            ]?.errors,
                                                                            name,
                                                                            error
                                                                        )}
                                                                        name={name}
                                                                        size={FormInputSize.Medium}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                            debouncedWriteToRedux();
                                                                        }}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        fullWidth
                                                                    />
                                                                    <FieldErrorMessage
                                                                        message={error?.message}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <EmptyBlock />
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        data-cy="details-zip-code"
                                                        id={`travellers.${travellerIndex}.zipcode`}
                                                    >
                                                        <FormLabel>
                                                            {t('details_zipcode')}
                                                        </FormLabel>
                                                        <Controller
                                                            name={`travellers.${travellerIndex}.zipcode`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    name,
                                                                    onChange,
                                                                    value,
                                                                    onBlur,
                                                                },
                                                                fieldState: { error, isTouched },
                                                            }) => (
                                                                <>
                                                                    <FormInput
                                                                        success={
                                                                            validateFormOnBlur &&
                                                                            isTouched &&
                                                                            !error
                                                                        }
                                                                        hasError={hasError(
                                                                            travellerErrors[
                                                                                travellerIndex
                                                                            ]?.errors,
                                                                            name,
                                                                            error
                                                                        )}
                                                                        name={name}
                                                                        size={FormInputSize.Medium}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                            debouncedWriteToRedux();
                                                                        }}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        fullWidth
                                                                    />
                                                                    <FieldErrorMessage
                                                                        message={error?.message}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        data-cy="details-city"
                                                        id={`travellers.${travellerIndex}.city`}
                                                    >
                                                        <FormLabel>{t('details_city')}*</FormLabel>
                                                        <Controller
                                                            name={`travellers.${travellerIndex}.city`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    name,
                                                                    onChange,
                                                                    value,
                                                                    onBlur,
                                                                },
                                                                fieldState: { error, isTouched },
                                                            }) => (
                                                                <>
                                                                    <FormInput
                                                                        success={
                                                                            validateFormOnBlur &&
                                                                            isTouched &&
                                                                            !error
                                                                        }
                                                                        hasError={hasError(
                                                                            travellerErrors[
                                                                                travellerIndex
                                                                            ]?.errors,
                                                                            name,
                                                                            error
                                                                        )}
                                                                        name={name}
                                                                        size={FormInputSize.Medium}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                            debouncedWriteToRedux();
                                                                        }}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        fullWidth
                                                                    />
                                                                    <FieldErrorMessage
                                                                        message={error?.message}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            ) : (
                                                <EmptyBlock />
                                            )}
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                data-cy="details-country"
                                                id={`travellers.${travellerIndex}.country`}
                                            >
                                                <FormLabel>
                                                    {t('details_countrycode')}
                                                    {traveller.extended ? '*' : ''}
                                                </FormLabel>
                                                <Controller
                                                    name={`travellers.${travellerIndex}.country`}
                                                    control={control}
                                                    render={({
                                                        field: { name, onChange, value, onBlur },
                                                        fieldState: { error },
                                                    }) => (
                                                        <>
                                                            <FormSelect
                                                                hasError={hasError(
                                                                    travellerErrors[travellerIndex]
                                                                        ?.errors,
                                                                    'country_code',
                                                                    error
                                                                )}
                                                                options={mapCountriesToDropdownOptions()}
                                                                onBlur={onBlur}
                                                                onChange={(value) => {
                                                                    onChange({
                                                                        target: {
                                                                            name,
                                                                            value,
                                                                        },
                                                                    });

                                                                    debouncedWriteToRedux();
                                                                }}
                                                                value={value}
                                                                placeholder={t(
                                                                    'form_select_country_placeholder'
                                                                )}
                                                            />
                                                            <FieldErrorMessage
                                                                message={error?.message}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <hr className={classes.line} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Heading variant="h3" marginTop={false}>
                                                    {t('details_contact')}
                                                </Heading>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                data-cy="details-email"
                                                id={`travellers.${travellerIndex}.email`}
                                            >
                                                <FormLabel>
                                                    {t('details_email')}*
                                                    {emailIsValidating && (
                                                        <Spinner
                                                            className={classes.emailLoadingSpinner}
                                                        />
                                                    )}
                                                </FormLabel>
                                                <Controller
                                                    name={`travellers.${travellerIndex}.email`}
                                                    control={control}
                                                    render={({
                                                        field: { name, onChange, value, onBlur },
                                                        fieldState: { error, isTouched },
                                                    }) => (
                                                        <>
                                                            <FormInput
                                                                onBlur={onBlur}
                                                                success={
                                                                    validateFormOnBlur &&
                                                                    isTouched &&
                                                                    !error &&
                                                                    !hasEmailError &&
                                                                    !emailIsValidating
                                                                }
                                                                hasError={
                                                                    hasError(
                                                                        travellerErrors[
                                                                            travellerIndex
                                                                        ]?.errors,
                                                                        'email',
                                                                        error
                                                                    ) || hasEmailError
                                                                }
                                                                name={name}
                                                                size={FormInputSize.Medium}
                                                                onChange={(e) => {
                                                                    onChange(e);
                                                                    debouncedWriteToRedux();
                                                                }}
                                                                value={value}
                                                                fullWidth
                                                            />
                                                            {emailErrors && (
                                                                <EmailErrorMessage
                                                                    selectSuggestion={
                                                                        selectSuggestion
                                                                    }
                                                                    emailErrors={emailErrors}
                                                                />
                                                            )}

                                                            <FieldErrorMessage
                                                                message={error?.message}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                {travellerIndex === 0 && (
                                                    <div
                                                        className={
                                                            travellerDetailsCss.consentCheckbox
                                                        }
                                                    >
                                                        <Checkbox
                                                            name="newsletter_consent_checkbox"
                                                            id="newsletter_consent_checkbox"
                                                            checked={hasConsentedNewsLetter}
                                                            onChange={() => {
                                                                dispatch(
                                                                    setConsentedNewsletter(
                                                                        !hasConsentedNewsLetter
                                                                    )
                                                                );
                                                            }}
                                                            label={
                                                                <Body
                                                                    className={
                                                                        travellerDetailsCss.consentLabel
                                                                    }
                                                                    marginBottom={false}
                                                                    marginTop={false}
                                                                >
                                                                    {t(
                                                                        'payment_consent_newsletter'
                                                                    )}
                                                                </Body>
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </Grid>

                                            <EmptyRow />

                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                data-cy="details-phone"
                                                id={`travellers.${travellerIndex}.phoneNumber`}
                                            >
                                                <FormLabel>{t('details_phonenumber')}*</FormLabel>
                                                <Controller
                                                    name={`travellers.${travellerIndex}.phoneNumber`}
                                                    control={control}
                                                    render={({
                                                        field: { name, value: formValue },
                                                    }) => (
                                                        <>
                                                            <PhoneNumberSelect
                                                                onChange={(value) => {
                                                                    if (value !== formValue) {
                                                                        setValue(name, value);
                                                                        debouncedWriteToRedux();
                                                                    }
                                                                }}
                                                                initialValue={formValue}
                                                                hasError={
                                                                    !!formErrors?.travellers?.[
                                                                        travellerIndex
                                                                    ]?.phoneNumber?.message
                                                                }
                                                            />
                                                            <FormHintMessage
                                                                message={t('details_phone_hint')}
                                                            />
                                                            <FieldErrorMessage
                                                                message={
                                                                    formErrors?.travellers?.[
                                                                        travellerIndex
                                                                    ]?.phoneNumber?.message
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Grid>

                                            {isB2BMode && (
                                                <>
                                                    <EmptyRow />
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                        id={`travellers.${travellerIndex}.reference`}
                                                    >
                                                        <FormLabel>
                                                            {t('details_reference')}
                                                        </FormLabel>
                                                        <Controller
                                                            name={`travellers.${travellerIndex}.reference`}
                                                            control={control}
                                                            render={({
                                                                field: {
                                                                    name,
                                                                    onChange,
                                                                    value,
                                                                    onBlur,
                                                                },
                                                                fieldState: { error, isTouched },
                                                            }) => (
                                                                <>
                                                                    <FormInput
                                                                        success={
                                                                            validateFormOnBlur &&
                                                                            isTouched &&
                                                                            !error
                                                                        }
                                                                        name={name}
                                                                        size={FormInputSize.Medium}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                            debouncedWriteToRedux();
                                                                        }}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        fullWidth
                                                                    />
                                                                    <FormHintMessage
                                                                        message={t(
                                                                            'details_reference_hint'
                                                                        )}
                                                                    />
                                                                    <FieldErrorMessage
                                                                        message={error?.message}
                                                                    />
                                                                </>
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>

                                {travellerIndex === 0 ? (
                                    <div className={travellerDetailsCss.discountContainer}>
                                        <DiscountsFeature />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </CardBody>
                        </Card>
                        {travellerIndex !== travellers.length - 1 && <VSpacer />}
                    </section>
                );
            })}

            {eventHasRestrictions ? (
                <>
                    <VSpacer />
                    <Card className="fullWidthCard">
                        <CardBody>
                            <Heading
                                variant="h2"
                                marginTop={false}
                                marginBottom={false}
                                dataCy="event-restrictions-title"
                            >
                                {t('details_restrictions_title')}
                            </Heading>
                            <Body className={classes.restrictionsDescriptions} marginBottom={false}>
                                {t('details_restrictions_description')}
                            </Body>

                            <ul className={classes.restrictionsList}>
                                {!restrictions?.allowAwayFans && (
                                    <li>
                                        {t('details_restrictions_no_away_fans', {
                                            team: awayTeam,
                                        })}
                                    </li>
                                )}

                                {hasBlacklistedCountries && (
                                    <li>
                                        {t('details_restrictions_blacklisted_countries', {
                                            countries: restrictions?.blacklistedCountries
                                                ?.map((code) => getTranslatedCountryByCode(code))
                                                .join(', '),
                                        })}
                                    </li>
                                )}

                                {hasBlacklistedNationalities && (
                                    <li>
                                        {t('details_restrictions_blacklisted_nationalities', {
                                            nationalities: restrictions?.blacklistedNationalities
                                                ?.map((code) => getNationalityByCode(code)?.name)
                                                .join(', '),
                                        })}
                                    </li>
                                )}

                                <li>{t('details_restrictions_passport_control')}</li>
                            </ul>
                            <Controller
                                name="restrictionsConfirmed"
                                control={control}
                                render={({ field: { name, value }, fieldState: { error } }) => (
                                    <>
                                        <Checkbox
                                            dataCy="confirm-restrictions"
                                            label={getRestrictionsCheckboxText()}
                                            className={classes.checkboxContainer}
                                            id={name}
                                            name={name}
                                            onChange={() => {
                                                setValue(
                                                    'restrictionsConfirmed',
                                                    !getValues().restrictionsConfirmed,
                                                    { shouldValidate: true }
                                                );
                                            }}
                                            checked={value}
                                        />
                                        <FieldErrorMessage message={error?.message} />
                                    </>
                                )}
                            />
                        </CardBody>
                    </Card>
                </>
            ) : null}

            {!!matchingError && (
                <div className={classes.notMatchingError}>
                    <ErrorMessage content={matchingError} />
                </div>
            )}

            {validationErrors.length > 0 && (
                <>
                    {validationErrors.map((error, index) => (
                        <div className={classes.notMatchingError} key={`${error}-${index}`}>
                            <ErrorMessage content={error} />
                        </div>
                    ))}
                </>
            )}

            {!matchingError && validationErrors.length === 0 && <VSpacer />}

            <ConfirmButton
                inverted
                buttonProps={{
                    text: microCopyVariant
                        ? t('microCopyGeneralConfirmButtonText')
                        : t('details_to_overview'),
                    isLoading: isSubmitting,
                    disabled: isSubmitting || emailIsValidating,
                    dataCy: 'finalise-and-pay',
                    onClick: handleSubmit(
                        (values) =>
                            !emailErrors?.certainly_wrong && onSubmit(values, internalBookOther)
                    ),
                }}
                infoText={!microCopyVariant ? t('details_finalize_hint') : ''}
                infoTextConfig={{
                    darkText: true,
                    bigText: true,
                }}
                microCopyText={[t('traveller_details_micro_copy')]}
                onClickPrevious={() => {
                    if (session) {
                        window.location.href = RouteHelper.getHotelRoute(session.eventId);
                    }
                }}
            />
        </>
    );
};

export default TravellerDetailsForm;

type FieldErrorLabelsProps = {
    errors: string[];
};

function FieldErrorLabels({ errors }: FieldErrorLabelsProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            {errors.map((key, index) => (
                <React.Fragment key={`${index}-${key}`}>
                    <span className={classes.errorDetailName}>{t(`details_${key}`)}</span>
                    {index !== errors.length - 1 ? <span>, </span> : ''}
                </React.Fragment>
            ))}
        </>
    );
}
