import classnames from 'classnames';
import * as React from 'react';
import { OptionsListItem } from 'src/view/components/FormSelect/FormSelect';
import { FormListOption, FormSelect } from '..';
import $ from './FilterSelect.module.scss';

export interface Option {
    title: string;
    subtitle?: string;
}

interface Props {
    options: Option[];
    wide: boolean;
    onChange: (value: string) => void;
    selected?: Option;
    id?: string;
    secondary?: boolean;
}

// eslint-disable-next-line react/prefer-stateless-function
class FilterSelect extends React.Component<Props> {
    public static defaultProps = { wide: false };

    public constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
    }

    public onChange(id: string) {
        const { onChange } = this.props;
        onChange(id);
    }

    public onChangeMobile(id: string) {
        const { onChange, options } = this.props;
        const indexOfSelectedOption = options.findIndex((option) => option.title === id);
        onChange(indexOfSelectedOption.toString());
    }

    public mapSelectDropdownOptions(): OptionsListItem[] {
        const { options } = this.props;
        const selectOptions = options.map((option) => option.title);

        return [
            {
                options: selectOptions.map((o) => ({
                    label: o,
                    value: o,
                })),
            },
        ];
    }

    public render() {
        const { options, wide, selected, secondary } = this.props;

        return (
            <div className={classnames([$.select, secondary && $.secondary])}>
                <div className={$.mobileFilter}>
                    <FormSelect
                        options={this.mapSelectDropdownOptions()}
                        onChange={this.onChangeMobile}
                        wide={wide}
                        value={selected?.title}
                    />
                </div>
                <div className={$.filter}>
                    {options.map((option, index) => (
                        <FormListOption
                            key={index.toString()}
                            title={option.title}
                            subTitle={option.subtitle}
                            name={option.title}
                            id={index.toString()}
                            checked={selected === option}
                            onChange={this.onChange}
                            secondary={secondary}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default FilterSelect;
