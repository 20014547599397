import { getRouteLocaleParam, getTravelwareLang } from 'src/app/constants/appLocale';
import { TravelwareApiConfig } from 'src/app/core/entities/TravelwareApiConfig';
import * as ExceptionLogger from 'src/app/utils/exceptionLogger';
import { Traveler } from '../models/Traveler';
import { commonAxios } from './axios';

export interface Body {
    [key: string]:
        | Body
        | Body[]
        | string
        | string[]
        | boolean
        | number
        | number[]
        | Traveler[]
        | undefined
        | null;
}

interface FetchUrlParams {
    completeUrl?: string;
    endpoint: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: any;
    userToken?: string;
    getSecret?: boolean;
    secret?: string;
    showCompleteResponse?: boolean;
    mockServerFailDEV?: boolean;
}

let travelwareApiConfig: TravelwareApiConfig;

export function setTravelwareApiConfig(config: TravelwareApiConfig) {
    travelwareApiConfig = config;
    ExceptionLogger.setTravelwareApiConfigScope(travelwareApiConfig);
}

export function getTravelwareApiConfig() {
    return travelwareApiConfig;
}

export async function fetchUrl<Entity>({
    endpoint,
    method = 'GET',
    body,
    getSecret,
    secret,
    showCompleteResponse = false,
    mockServerFailDEV = false,
}: FetchUrlParams): Promise<Entity> {
    if (!travelwareApiConfig) {
        throw new Error('Missing travelwareApiConfig');
    }

    const params = {
        method,
        data: body,
        headers: (() => {
            const headers: { [key: string]: string } = {
                'Accept-Language': getTravelwareLang(),
            };

            if (!travelwareApiConfig.throughProxy) {
                headers.Authorization = `Bearer ${travelwareApiConfig.key}`;
            }

            if (secret) headers['X-TW-Order-Secret'] = secret;
            return headers;
        })(),
    };

    const sanitizedEndpoint = endpoint.replace(/^\/+/, '');
    const url = new URL(`${travelwareApiConfig.baseUrl}/api/${sanitizedEndpoint}`);

    // Append "locale" in the query string
    // So that in njs_tw_auth_filter.js,
    // `getMatchingApiKey` is able to get locale-specific data from TW1.
    const domainLocale = getRouteLocaleParam();
    if (domainLocale) url.searchParams.set('locale', domainLocale);

    const response = await commonAxios(url.toString(), params);

    const dataObject = showCompleteResponse ? response.data : response.data.data;

    if (getSecret) {
        return {
            ...dataObject,
            secret: response.headers['x-tw-order-secret'],
        };
    }

    return dataObject as Entity;
}
