import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { colors } from 'src/app/constants/theme';
import { useAppLocale } from 'src/app/hooks/use-locale';
import { parseLink } from 'src/app/utils/utils';
import { getOrderSummary } from 'src/data/services/order';
import { CheckIcon } from 'src/images/icons/CheckIcon';
import { ErrorMessage, InfoMessage } from 'src/view/components';
import { Body } from 'src/view/components/Body/Body';
import { Collapsable } from 'src/view/components/Collapsable/Collapsable';
import { Heading } from 'src/view/components/Heading/Heading';
import { VSpacer } from 'src/view/components/Page';
import { OrderSummary, OrderSummaryProps } from 'src/view/components/order-summary/OrderSummary';
import { Loading } from '../Loading/Loading';

const useStyles = makeStyles(() => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    orderSummary: {
        marginTop: '2rem',
    },
    checkIcon: {
        height: 35,
        width: 35,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `3px solid ${colors.successGreen}`,
        padding: 5,
        borderRadius: '50%',
        marginLeft: '1rem',
    },
}));

interface PaymentAuthorisedProps {
    orderId: string;
    orderSecret: string;
    resultCode?: 'Authorised' | 'Pending';
}

export const PaymentAuthorised = ({
    orderId,
    orderSecret,
    resultCode = 'Authorised',
}: PaymentAuthorisedProps) => {
    const classes = useStyles();
    const locale = useAppLocale();

    const { isLoading, isError, data } = useQuery({
        queryKey: ['get-order-summary', orderId, orderSecret, locale],
        queryFn: () => getOrderSummary(orderId, orderSecret),
    });

    const { t } = useTranslation();

    if (isLoading)
        <div className={classes.center}>
            <VSpacer />
            <Loading />
        </div>;

    if (isError) {
        return (
            <>
                <VSpacer />
                <ErrorMessage content="We could not load the order summary. Check your e-mail." />
            </>
        );
    }

    if (!data) return null;

    const bookingFee = (data.extras ?? []).find((extra) => extra.type === 'BookingFee');

    const {
        name,
        start_date,
        date_confirmed,
        tickets_sent_earliest_days,
        tickets_sent_latest_days,
    } = data.event;
    const { name: venueName, city, country_code } = data.venue;
    const { amount, currency } = data.total_price;

    const { event, tickets, venue, accomodation, totalPrice }: OrderSummaryProps = {
        event: {
            name,
            startDate: start_date,
            dateConfirmed: date_confirmed,
            SentEarliestDays: tickets_sent_earliest_days,
            SentLatestDays: tickets_sent_latest_days,
        },
        tickets: data.tickets.map((ticket) => ({
            category: ticket.category,
            quantity: ticket.quantity,
            imageUrl: ticket.image_url,
        })),
        venue: {
            name: venueName,
            city,
            countryCode: country_code,
        },
        accomodation: data.accommodation
            ? {
                  name: data.accommodation.name,
                  imageUrl: data.accommodation.image_url,
                  checkIn: data.accommodation.check_in,
                  checkOut: data.accommodation.check_out,
                  paxes: data.accommodation.paxes,
                  rooms: data.accommodation.rooms,
              }
            : undefined,
        totalPrice: {
            amount,
            currency,
        },
    };

    return (
        <>
            <Heading variant="h1">
                {t('payment_completed_success_title', {
                    name: data.customer.name,
                })}
                <div className={classes.checkIcon} data-cy="payment-complete">
                    <CheckIcon color={colors.successGreen} />
                </div>
            </Heading>
            {resultCode === 'Pending' ? (
                <InfoMessage text={t('payment_completed_pending_description')} />
            ) : (
                <>
                    <Body>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: parseLink(
                                    t('payment_completed_success_description', {
                                        email: data.customer.email,
                                    })
                                ),
                            }}
                        />
                    </Body>
                    <Collapsable
                        title={t('payment_completed_success_no_email_received')}
                        initialOpened={false}
                    >
                        <Body>
                            {t('payment_completed_success_no_email_received_description_1')}
                        </Body>
                    </Collapsable>
                </>
            )}

            <OrderSummary
                className={classes.orderSummary}
                event={event}
                tickets={tickets}
                venue={venue}
                accomodation={accomodation}
                totalPrice={totalPrice}
                bookingFee={bookingFee}
            />
        </>
    );
};
