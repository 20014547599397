import '@adyen/adyen-web/dist/adyen.css';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { RouteLeavingGuard } from 'src/app/components/navigation-blocker/route-leaving-guard';
import { appConstants } from 'src/app/constants/app';
import { useNavigate } from 'src/app/hooks/use-navigate';
import { TicketsNotAvailableModal } from 'src/app/modals/payment/TicketsNotAvailableModal';
import { PaymentUserDetails } from 'src/app/pages/Payment/features/PaymentUserDetails/PaymentUserDetails';
import { useTypedDispatch } from 'src/app/store';
import {
    requireActiveSessionOrReset,
    selectIsTravelwareAvailable,
    selectPartnerConfig,
    selectReferrerUrl,
    selectSession,
} from 'src/app/store/appSlice';
import { selectEventDetail } from 'src/app/store/eventSlice';
import { fetchAvailability, selectOrder, selectOrderSummary } from 'src/app/store/orderSlice';
import { RouteHelper } from 'src/app/utils/RouteHelper';
import { pushStepEventToDataLayer } from 'src/app/utils/googleAnalytics';
import { getLastKnownEntryPoint } from 'src/app/utils/lastKnownEntryPointHelper';
import { parseLink } from 'src/app/utils/utils';
import { getBooking } from 'src/data/services/cache';
import { CheckIcon } from 'src/images/icons/CheckIcon';
import { Page } from 'src/view/components';
import AlertMessage from 'src/view/components/AlertMessage/AlertMessage';
import { Body } from 'src/view/components/Body/Body';
import Button from 'src/view/components/Button/Button';
import Card, { CardBody } from 'src/view/components/Card';
import { Heading } from 'src/view/components/Heading/Heading';
import VSpacer from 'src/view/components/Page/VSpacer';
import { PaymentFeature } from 'src/view/features/payment/PaymentFeature';
import { Loading } from '../Loading/Loading';
import { ServiceUnavailable } from '../ServiceUnavailable/ServiceUnavailable';
import './Adyen.scss';
import $ from './Payment.module.scss';

export function PaymentPage() {
    const dispatch = useTypedDispatch();
    const partnerConfig = useSelector(selectPartnerConfig);
    const isTravelwareAvailable = useSelector(selectIsTravelwareAvailable);
    const eventDetail = useSelector(selectEventDetail);
    const order = useSelector(selectOrder);
    const session = useSelector(selectSession);
    const orderSummary = useSelector(selectOrderSummary);

    const [showTicketsNotAvailableModal, setShowTicketsNotAvailableModal] = useState(false);

    const [isOrderStatusBooked, setIsOrderStatusBooked] = useState(false);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { eventId } = useParams<{ eventId: string }>();

    useEffect(() => {
        requireActiveSessionOrReset(eventId);
        pushStepEventToDataLayer({
            event_type: 'step_start',
            step_name: 'payment',
        });
    }, [eventId]);

    if (!isTravelwareAvailable) {
        return <ServiceUnavailable />;
    }

    if (!session || !order || !partnerConfig) return <Loading />;

    const ticketPagePath = RouteHelper.getTicketRoute(session.eventId, {
        category_id: session.baseTicketCategoryId || undefined,
    });
    const navigateToTicketPage = () => navigate(ticketPagePath);

    return (
        <Page step={3} eventDetail={eventDetail || undefined} hideCountDown>
            <RouteLeavingGuard
                when={true}
                redirectTo={ticketPagePath}
                shouldBlockNavigation={(location) => {
                    // Block navigation if user is trying to navigate back
                    return (
                        location.pathname.includes('/details') ||
                        location.pathname.includes('/ticket')
                    );
                }}
                title={t(
                    'preventLeavingPaymentPage.title',
                    'Are you sure you want to leave this page?'
                )}
                text={t(
                    'preventLeavingPaymentPage.text',
                    'Your personal details will be saved, but you will need to set up your order again. Do you want to go back?'
                )}
                confirmText={t('preventLeavingPaymentPage.confirmText', 'Back to the first step')}
                cancelText={t('preventLeavingPaymentPage.cancelText', 'Stay on this page')}
            />
            <TicketsNotAvailableModal
                onClick={() => {
                    dispatch(fetchAvailability(true));
                    navigateToTicketPage();
                }}
                onClose={() => setShowTicketsNotAvailableModal(false)}
                open={showTicketsNotAvailableModal}
            />

            <Box>
                <Link className={$.backLink} to={ticketPagePath}>
                    {t('goBack', 'Go back')}
                </Link>
            </Box>
            <VSpacer />
            <div>
                <Card className="fullWidthCard">
                    <CardBody className={$.personalDetailsCardBody}>
                        <PaymentUserDetails />
                    </CardBody>
                </Card>
            </div>
            <VSpacer />
            <div data-cy="payment-page">
                <Card className="fullWidthCard">
                    <CardBody>
                        <Heading variant="h2" marginTop={false}>
                            {t('choosePaymentMethod', 'Choose payment method')}
                        </Heading>
                        {isOrderStatusBooked ? (
                            <OrderHasAlreadyBeenPaid />
                        ) : (
                            <>
                                {orderSummary?.discountedPriceTotal === 0 && (
                                    <AlertMessage
                                        icon={<CheckIcon />}
                                        message={t('traveldetails_payment_amount_reached')}
                                    />
                                )}
                                {orderSummary?.discountedPriceTotal === 0 && (
                                    <AlertMessage
                                        icon={<CheckIcon />}
                                        message={t('traveldetails_payment_amount_reached')}
                                    />
                                )}

                                <Body>
                                    <div
                                        className={$.paymentTerms}
                                        dangerouslySetInnerHTML={{
                                            __html: `${parseLink(
                                                t('payment_acceptgeneral', {
                                                    url:
                                                        partnerConfig.termsAndConditionsUrl ||
                                                        appConstants.termsAndConditionsUrl,
                                                }),
                                                true
                                            )} ${
                                                eventDetail?.organizer?.hasTermsAndConditions
                                                    ? parseLink(
                                                          t(
                                                              'payment_acceptgeneral_organizer_terms',
                                                              {
                                                                  url: eventDetail.organizer
                                                                      ?.termsAndConditionsUrl,
                                                              }
                                                          ),
                                                          true
                                                      )
                                                    : ''
                                            } `,
                                        }}
                                    />
                                </Body>

                                <PaymentFeature setIsOrderStatusBooked={setIsOrderStatusBooked} />
                            </>
                        )}
                    </CardBody>
                </Card>
            </div>
        </Page>
    );
}

const OrderHasAlreadyBeenPaid = () => {
    const partnerReferrerUrl = useSelector(selectReferrerUrl);
    const homeUrl = partnerReferrerUrl ? partnerReferrerUrl?.toString() : getLastKnownEntryPoint();
    const bookingCodeFromCache = getBooking();
    const { t } = useTranslation();

    return (
        <>
            <Heading variant={'h2'}>
                {t('orderHasAlreadyBeenPaid', 'Order has already been paid.')}
            </Heading>
            <p>
                {t(
                    'checkYourEmailForDetails',
                    'Please check your email for details. Booking reference:'
                )}
                <strong>{bookingCodeFromCache}</strong>
            </p>
            <Button
                icon="/images/icon-home.svg"
                text={t('thanks_returnhome', 'Go to homepage')}
                onClick={() => {
                    if (homeUrl) window.location.href = homeUrl;
                }}
                dataCy="go-to-homepage"
            />
        </>
    );
};
