import MailOutlineIcon from '@mui/icons-material/MailOutline';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getContactData } from 'src/app/constants/contactData';
import { getOnlineStatusByOfficeHours } from 'src/app/helpers/getOnlineStatusByOfficeHours';
import $ from 'src/app/views/contactPossibilties/contactPossibilities.module.scss';
import ChatService from 'src/data/services/ChatService';
import { ChatIcon } from 'src/images/icons/ChatIcon';
import { PhoneIcon } from 'src/images/icons/PhoneIcon';
import { WhatsAppIcon } from 'src/images/icons/WhatsAppIcon';
import { BackgroundVariant, ContactLink } from 'src/view/components/ContactLink/ContactLink';

interface HideConfig {
    email?: boolean;
    chat?: boolean;
    whatsapp?: boolean;
    phonenumber?: boolean;
}

type Config = {
    hide: HideConfig;
    itemsBackgroundVariant?: BackgroundVariant;
};

interface ContactPossiblitiesListProps {
    whatsappUrl?: string;
    phoneNumber?: string;
    email?: string;
    onClickItem?: () => void;
    config?: Config;
}

export const ContactPossiblitiesList = ({
    whatsappUrl,
    phoneNumber,
    email,
    onClickItem,
    config,
}: ContactPossiblitiesListProps) => {
    const { t } = useTranslation();

    const handleClickChat = () => {
        ChatService.show();
        onClickItem?.();
    };

    const renderStatusDot = (online: boolean) => {
        if (online) {
            return <div className={$.statusDot} />;
        }

        return <div className={classNames($.statusDot, $.statusDotOffline)} />;
    };

    return (
        <>
            {ChatService.isInitialized && !config?.hide.chat && (
                <ContactLink
                    onClick={handleClickChat}
                    icon={<ChatIcon />}
                    contactTitle={t('supportblock_chat')}
                    subTitle={renderStatusDot(ChatService.getOnlineStatus())}
                    data-cy="contact-chat"
                    data-track-contactoption="chat"
                    backgroundVariant={config?.itemsBackgroundVariant}
                />
            )}

            {whatsappUrl && !config?.hide.whatsapp && (
                <ContactLink
                    type="anchor"
                    onClick={onClickItem}
                    href={whatsappUrl}
                    icon={<WhatsAppIcon />}
                    contactTitle={t('supportblock_whatsapp')}
                    subTitle={renderStatusDot(getOnlineStatusByOfficeHours())}
                    data-cy="contact-whatsapp"
                    data-track-contactoption="whatsapp"
                    backgroundVariant={config?.itemsBackgroundVariant}
                />
            )}

            {phoneNumber && !config?.hide.phonenumber && (
                <ContactLink
                    type="anchor"
                    onClick={onClickItem}
                    href={`tel:${phoneNumber}`}
                    icon={<PhoneIcon />}
                    contactTitle={<span className={$.contactLinkText}>{t('general_call')}</span>}
                    subTitle={<div className={$.contactLinkGreyText}>{phoneNumber}</div>}
                    data-cy="contact-phone"
                    data-track-contactoption="phone"
                    backgroundVariant={config?.itemsBackgroundVariant}
                />
            )}

            {email && !config?.hide.email && (
                <ContactLink
                    type="anchor"
                    onClick={onClickItem}
                    href={`mailto:${email}`}
                    icon={<MailOutlineIcon />}
                    contactTitle={<span className={$.contactLinkText}>{t('general_mail')}</span>}
                    subTitle={<div className={$.contactLinkGreyText}>{email}</div>}
                    data-cy="contact-email"
                    data-track-contactoption="email"
                    backgroundVariant={config?.itemsBackgroundVariant}
                />
            )}
        </>
    );
};

/** Returns the config for either B2B or B2C support. Does not include sales. */
export const useSupportContactPossibiltiesConfig = () => {
    const { t } = useTranslation();
    // const isB2B = useSelector(selectIsB2BMode) || window.location.hostname.includes('b2b'); // double check, because we can't rely on the store if the server had an error (e.g. 500)
    const isB2B = true;

    const b2bSupportOptions: ContactPossiblitiesListProps = {
        whatsappUrl: getContactData(t).b2bPhoneNumberWhatsapp,
        phoneNumber: getContactData(t).b2bPhoneNumberSupport,
        email: getContactData(t).b2bEmailSupport,
        config: {
            hide: {
                chat: true,
            },
            itemsBackgroundVariant: 'white',
        },
    };

    const b2cSupportOptions: ContactPossiblitiesListProps = {
        whatsappUrl: getContactData(t).whatsappUrl,
        phoneNumber: getContactData(t).phoneNumber,
        email: getContactData(t).email,
        config: {
            hide: {
                chat: false,
            },
            itemsBackgroundVariant: 'white',
        },
    };

    return isB2B ? b2bSupportOptions : b2cSupportOptions;
};
